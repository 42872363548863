import { ALoopTimer } from "../classes/ALoopTimer.js";
import { AScanDevice } from "../classes/scandevices/AScanDevice.js";
import { moveTo } from "../utils/scanauto-geo-tools.js";
export class ANavigationTimer extends ALoopTimer {
    reloadTimeout() { super.changeTimeout(this.getTimeout()); }
    constructor({ device, getTimeout }) {
        super(() => this.interpolate(), { timeout: getTimeout(), loopLifeCycle: 'PAGE' });
        this.getTimeout = getTimeout;
        this.device = device;
        this.lastTime = Date.now();
    }
    centerVehicleOnMap() {
        if (this.device.config('centerVehicle')) {
            this.device.getMap()?.setOptions({
                center: this.device.pos
            });
        }
    }
    interpolate() {
        const deltaTime = (Date.now() - this.lastTime) / 1000.0;
        const { device } = this;
        if (device.selected.length === 0) {
            return;
        }
        const curPos = device.pos, targetPos = device.targetPos;
        const { pos, remainingDistance, heading } = moveTo(curPos, targetPos, (device.config('targetSpeed') / 3.600) * deltaTime);
        device.config('heading', heading);
        // const distanceInMetersPrecise = distancePrecise(curPos, pos.toJSON())
        // const kmPerHourPrecise = distanceInMetersPrecise / deltaTime * 3.600
        // const distanceInMetersSimple = distanceSimple(curPos, pos.toJSON())
        // const kmPerHourSimple = distanceInMetersSimple / deltaTime * 3.600
        // this.setStatistics({
        //   distanceToTarget: distanceToTarget,
        //   simple: { distanceInMeters: distanceInMetersSimple, kmPerHour: kmPerHourSimple },
        //   precise: { distanceInMeters: distanceInMetersPrecise, kmPerHour: kmPerHourPrecise }
        // })
        // If vehicle reaches MIN_DISTANCE meters mark, the next waypoint will be selected
        if (remainingDistance > AScanDevice.MIN_DISTANCE_FOR_NEXT_WAYPOINT) {
            device.position = pos;
        }
        else {
            device.position = targetPos;
            const { maxSpeed } = device.currentRouteItem;
            if (device.config('inheritStreetMaxSpeed') && device.config('targetSpeed') !== maxSpeed && !isNaN(maxSpeed) && maxSpeed > 0) {
                device.config('targetSpeed', maxSpeed);
            }
            device.selectNextWaysegment(1);
        }
        this.centerVehicleOnMap();
        this.lastTime = Date.now();
    }
}
