import { AColor } from "../colors/AColor.js";
import { MAP_OPTIONS } from "./AMapStructs.js";
export class AGeoPaintService {
    paintAll(geoInstances) {
        if (this.allowPaint(geoInstances[0])) {
            geoInstances.map((geoInstance) => {
                this.paint(geoInstance);
            });
        }
    }
    paint(geoInstance) {
        // TODO: Replace searchPaint with:
        // const mapOption = this.geoTypeToMapOption(geoType)
        // const colorConfig = this.getGeoObjectColor(mapOption, geoObject)
        // const zIndex = this.getGeoObjectZIndex(mapOption)
        const colorConfig = this.searchPaint(geoInstance);
        geoInstance.setOptions({
            ...colorConfig,
            strokeWeight: .5,
            zIndex: 3.,
        });
    }
    changeOpacityAll(geoInstances, opacity) {
        if (this.allowPaint(geoInstances[0])) {
            geoInstances.map(geoInstance => {
                geoInstance.setOptions({
                    opacity: opacity,
                    fillOpacity: opacity,
                    strokeOpacity: opacity
                });
            });
        }
    }
    allowPaint(geoInstance) {
        return (geoInstance !== undefined && geoInstance instanceof google.maps.Polygon);
    }
    searchPaint(geoInstance) {
        const psData = geoInstance.data;
        let colorConfig = {
            fillColor: '#000000',
            strokeColor: '#000000',
            strokeOpacity: 1.0,
            fillOpacity: 0.5,
        };
        switch (psData.Name) {
            case "Tax parking":
                colorConfig.fillColor = "#FFFF00";
                colorConfig.strokeColor = "#888800";
                break;
            case "Free parking":
                colorConfig.fillColor = "#99DD99";
                colorConfig.strokeColor = "#000000";
                break;
            case "Private Tax parking":
                colorConfig.fillColor = "#99DD99";
                colorConfig.strokeColor = "#000000";
                break;
            case "Motorcycle Parking":
                colorConfig.fillColor = "#00FF00";
                colorConfig.strokeColor = "#008800";
                break;
            case "Electric":
                colorConfig.fillColor = "#00FF00";
                colorConfig.strokeColor = "#008800";
                break;
        }
        // TODO: Check if this code logic is needed
        switch (psData.GeoLayer) {
            case MAP_OPTIONS.RouteArea:
                colorConfig = { fillColor: '#888888', fillOpacity: 0.4, strokeColor: '#000000', strokeOpacity: 0.6 };
                break;
            case MAP_OPTIONS.Zone:
                colorConfig = { fillColor: '#888888', fillOpacity: 0.4, strokeColor: '#000000', strokeOpacity: 0.6 };
                break;
            case MAP_OPTIONS.TemporaryZone:
                colorConfig = { fillColor: '#888888', fillOpacity: 0.4, strokeColor: '#000000', strokeOpacity: 0.6 };
                break;
            case MAP_OPTIONS.ParkingMachine:
                colorConfig = { strokeColor: '#880000', strokeOpacity: 1., fillColor: '#FF0000', fillOpacity: .8 };
                break;
            case MAP_OPTIONS.Area:
                colorConfig = { fillColor: '#ffffaa', fillOpacity: 0.5, strokeColor: '#888888', strokeOpacity: 0.3 };
                break;
            case MAP_OPTIONS.Segment:
                colorConfig = { fillColor: '#888888', fillOpacity: 0.2, strokeColor: '#000000', strokeOpacity: 0.3 };
                break;
        }
        if (psData.Attributes["KLEUR"]) {
            const hexColor = psData.Attributes["KLEUR"];
            colorConfig.fillColor = hexColor;
            colorConfig.strokeColor = new AColor(hexColor).hsv.lerpTo(new AColor('#000000').hsv, 0.5).hexi;
        }
        else if (psData.Attributes["BO.Color"]) {
            const bo_color = this.get_legend_from_name(psData.Attributes["BO.Color"], true);
            if (bo_color) {
                colorConfig.fillColor = bo_color.fill;
                colorConfig.strokeColor = bo_color.stroke;
            }
        }
        return colorConfig;
    }
    get_legend_from_name(name, useOpacity) {
        switch (name) {
            case "purple": return this.get_legend_purple(useOpacity);
            case "green": return this.get_legend_green(useOpacity);
            case "yellow": return this.get_legend_yellow(useOpacity);
            case "orange": return this.get_legend_orange(useOpacity);
            case "red": return this.get_legend_red(useOpacity);
            case "blue": return this.get_legend_blue(useOpacity);
            case "grey": return this.get_legend_grey(useOpacity);
            case "white": return this.get_legend_white(useOpacity);
            case "brown": return this.get_legend_brown(useOpacity);
        }
        return null;
    }
    get_legend_purple(useOpacity) {
        return {
            fill: useOpacity ? new AColor(171, 0, 227).rgba(0.7) : new AColor(171, 0, 227).hexi,
            stroke: new AColor(171, 0, 227).hexi
        };
    }
    get_legend_green(useOpacity) {
        return {
            fill: useOpacity ? new AColor(0, 255, 0).rgba(0.7) : new AColor(0, 255, 0).hexi,
            stroke: new AColor(5, 163, 0).hexi
        };
    }
    get_legend_orange(useOpacity) {
        return {
            fill: useOpacity ? new AColor(255, 133, 0).rgba(0.7) : new AColor(255, 133, 0).hexi,
            stroke: new AColor(256, 128, 0).hexi
        };
    }
    get_legend_red(useOpacity) {
        return {
            fill: useOpacity ? new AColor(247, 0, 0).rgba(0.7) : new AColor(247, 0, 0).hexi,
            stroke: new AColor(185, 0, 0).hexi
        };
    }
    get_legend_grey(useOpacity) {
        return {
            fill: useOpacity ? new AColor(128, 128, 128).rgba(0.7) : new AColor(128, 128, 128).hexi,
            stroke: new AColor(128, 128, 128).hexi
        };
    }
    get_legend_blue(useOpacity) {
        return {
            fill: useOpacity ? new AColor(0, 173, 255).rgba(0.7) : new AColor(0, 173, 255).hexi,
            stroke: new AColor(0, 114, 232).hexi
        };
    }
    get_legend_yellow(useOpacity) {
        return {
            fill: useOpacity ? new AColor(255, 255, 0).rgba(0.7) : new AColor(255, 255, 0).hexi,
            stroke: new AColor(255, 255, 0).hexi
        };
    }
    get_legend_white(useOpacity) {
        return {
            fill: useOpacity ? new AColor(255, 255, 255).rgba(0.7) : new AColor(255, 255, 255).hexi,
            stroke: new AColor(128, 128, 128).hexi
        };
    }
    get_legend_brown(useOpacity) {
        return {
            fill: useOpacity ? new AColor(200, 100, 0).rgba(0.7) : new AColor(200, 100, 0).hexi,
            stroke: new AColor(200, 100, 0).hexi
        };
    }
    get_legend_brown_outline() {
        return {
            fill: new AColor(200, 100, 0).rgba(0.0),
            stroke: new AColor(200, 100, 0).hexi
        };
    }
}
