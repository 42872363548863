import { ALoopTimer } from "../classes/ALoopTimer.js";
import { AGpsBufferTimer } from "./AGpsBufferTimer.js";
export class AGpsStateEmitterTimer extends ALoopTimer {
    reloadTimeout() { super.changeTimeout(this.getTimeout()); }
    constructor({ device, getTimeout }) {
        super(() => this.sendGpsStateToServer(), { timeout: getTimeout(), loopLifeCycle: 'PAGE' });
        this.getTimeout = getTimeout;
        this.device = device;
    }
    stop() {
        this.sendGpsStateToServer();
        return super.stop();
    }
    genGpsStateStreamV2() {
        const buffer = this.device.getTimer(AGpsBufferTimer).extractBuffer();
        const { Points, Times, Directions } = buffer;
        const [lng, lat] = Points[Points.length - 1];
        // const speed = magnitude({ lat: speedX, lng: speedY})
        return {
            DeviceLatitude: lat,
            DeviceLongitude: lng,
            Latitude: lat,
            Longitude: lng,
            GpsTime: Times[Times.length - 1],
            Direction: Directions[Directions.length - 1],
            Points: Points,
            Times: Times,
            Directions: Directions,
            ValidGps: 1,
            ValidGpsTime: 1,
            DeviceId: 0,
            Altitude: 55,
            CpuTime: null,
            SatelliteCount: Math.floor(5 + (Math.random() * 10 - 5)),
            Precision: (Math.random() * 2.0),
            SignalStrength: Math.round(50 + Math.random() * 50.0),
            GpsInterval: `${this.getTimeout()} MilliSeconds`,
            Speed: this.device.speed ?? 0.0123,
            SpeedX: this.device.speedX,
            SpeedY: this.device.speedY,
            GpsAntennaXRelToCenterPoint_M: 0.0,
            GpsAntennaYRelToCenterPoint_M: 0.0,
            Heading: this.device.config('heading') ?? 0.0,
            GpsDeviceType: 'Trimble',
            MessageUI: "",
        };
    }
    genGpsStateStreamV1() {
        return {
            Speed: this.device.speed,
            GpsTime: new Date().toJSON(),
            Latitude: this.device.position.lat(),
            Longitude: this.device.position.lng(),
            Direction: 0,
            Precision: 1,
            Altitude: 55
        };
    }
    sendGpsStateToServer() {
        const GpsStateStream = this.genGpsStateStreamV2();
        if (GpsStateStream.Points.length > 0) {
            requestService.stream('GpsStateStream', GpsStateStream, {
                priority: 10
            });
        }
    }
}
