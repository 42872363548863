import { AEngine, sleep } from "../core/AEngine.js";
import { AGeoUtils } from "../core/maps/AGeoUtils.js";
import { resetDetectionIndex, sendFakeDetection, WAYSEGMENT_GREY, WAYSEGMENT_HIGHLIGHT } from "../utils/scanauto-tools.js";
import { AScanDevice } from "../classes/scandevices/AScanDevice.js";
import { APreferenceService } from "../services/APreferenceService.js";
import { extractFormData, genForm, injectFormData, injectFormValues } from "../utils/forms.js";
import { addTabListenersFind } from "../utils/tabs.js";
import { ANavigationTimer } from "../timers/ANavigationTimer.js";
import { AGpsStateEmitterTimer } from "../timers/AGpsStateEmitterTimer.js";
import { ADetectionEmitterTimer } from "../timers/ADetectionEmitterTimer.js";
import { ACamStateEmitterTimer } from "../timers/ACamStateEmitterTimer.js";
import { ACoreMapService, createMap } from "../core/maps/ACoreMapService.js";
import { MAP_OPTIONS } from "../core/maps/AMapStructs.js";
import { ALERT_TITLES } from "../services/AAlertService.js";
import { AGpsBufferTimer } from "../timers/AGpsBufferTimer.js";
import { AError } from "../classes/AError.js";
export class APage {
    async testParkingSpaceTypes() {
        var _map = {};
        Object.values(this.parkingSpaces).map(v => {
            // const key = v.data.Name
            const key = v.data.Attributes['Functie'];
            if (!_map.hasOwnProperty(key)) {
                _map[key] = [];
            }
            _map[key].push(v);
        });
        for (const type in _map) {
            const ps = _map[type][0];
            const pos = AGeoUtils.calcCenter(ps);
            this.map.setCenter(pos);
            console.log(type, ps.data);
            console.log('click', new google.maps.event.trigger(ps, 'click'));
            await sleep(500);
        }
    }
    constructor() {
        this.markers = [];
        this.StartPosition = { lat: 0, lng: 0 };
        this.EndPosition = { lat: 0, lng: 0 };
        const defaultCoords = geoService.defaultCoordinates;
        this.startPos = defaultCoords.center.toJSON();
        this.startBounds = defaultCoords.bounds.toJSON();
        this.startPosition = new google.maps.LatLng(this.startPos);
        this.map = createMap('#map', {
            center: this.startPosition,
            zoom: 16
        });
        this.map.addListener('click', (e) => this.onMapClicked(e));
        // this.segments = []
        this.waySegments = [];
        this.waySegmentsMap = {};
        this.parkingSpaces = [];
        this.splitParkingSpaces = [];
        this.splitParkingSpacesDataMap = {};
        this.waySegmentToSideSplitParkingSpaceMap = {};
        this.hasParkingSpaces = false;
    }
    async init() {
        addTabListenersFind($('#AjaxContent'));
        this.handleInterfaceEvents();
        let segToWaySegMap = {};
        await Loading.waitForPromises([
            AEngine.get(ACoreMapService).prepareMapItems(MAP_OPTIONS.Area | MAP_OPTIONS.Zone | MAP_OPTIONS.ParkingSpace | MAP_OPTIONS.Segment),
            geoService.loadSegmentToWaySegmentMap().then((geoHashmap) => {
                segToWaySegMap = geoHashmap;
            }),
            geoService.load(this.map, MAP_OPTIONS.ParkingSpace, { parseToMap: true, addClickListener: true }).then((geoInstances) => {
                this.parkingSpaces = geoInstances;
            }),
            geoService.load(this.map, MAP_OPTIONS.SplitParkingSpace, { parseToMap: false, addClickListener: true }).then((geoInstances) => {
                this.splitParkingSpaces = geoInstances;
            }),
            geoService.load(this.map, MAP_OPTIONS.WaySegment, { parseToMap: true, addClickListener: this.onGeoWaySegmentClick }).then((geoInstances) => {
                this.waySegments = geoInstances;
                this.waySegmentsMap = this.convertToMap(this.waySegments, (waySegment) => waySegment.data.GeoId);
            }),
        ]);
        // const segToWaySegMap = await geoService.loadSegmentToWaySegmentMap()
        // const segIdToPsId = await AEngine.get(AParkingSpaceService).loadSegmentToSplitParkingSpaceMap()
        // this.splitParkingSpaces = await Loading.waitForPromises(
        //   geoService.load(this.map, MAP_OPTIONS.SplitParkingSpace, { parseToMap: false }))
        // this.parkingSpaces = await Loading.waitForPromises(
        //   geoService.load(this.map, MAP_OPTIONS.ParkingSpace, { parseToMap: true, addClickListener: true }))
        // this.parkingSpaces.map((parkingSpace) => {
        //   coreMapService.addClickListener(parkingSpace)
        // })
        // value = list of splitparkingspaceids
        // Key 1 = WaySegmentId, key 2 = WaySegmentSide
        // Value = list of ParkingSpaceIds
        this.waySegmentToSideSplitParkingSpaceMap = {};
        this.splitParkingSpaces.map(v => {
            const splitParkingSpaceId = v.data.GeoId;
            const { SegmentId } = v.data.Attributes;
            if (SegmentId != null) {
                const { WaySegmentId, WaySegmentSide } = segToWaySegMap[SegmentId];
                if (!this.waySegmentToSideSplitParkingSpaceMap.hasOwnProperty(WaySegmentId)) {
                    this.waySegmentToSideSplitParkingSpaceMap[WaySegmentId] = {};
                }
                if (!this.waySegmentToSideSplitParkingSpaceMap[WaySegmentId].hasOwnProperty(WaySegmentSide)) {
                    this.waySegmentToSideSplitParkingSpaceMap[WaySegmentId][WaySegmentSide] = [];
                }
                this.waySegmentToSideSplitParkingSpaceMap[WaySegmentId][WaySegmentSide].push(splitParkingSpaceId);
            }
        });
        this.hasParkingSpaces = Object.keys(this.waySegmentToSideSplitParkingSpaceMap).length > 0;
        // TODO: Use initial GeoMap Instead of creating a new map
        this.splitParkingSpacesDataMap = this.convertToMap(this.splitParkingSpaces.filter((parkingSpace) => parkingSpace.data.Attributes.SegmentId != null), (parkingSpace) => parkingSpace.data.GeoId, (parkingSpace) => parkingSpace);
        const scanDevice = new AScanDevice({ startPosition: this.startPosition, map: this.map });
        this.scanDevice = scanDevice
            .addTimer(new ANavigationTimer({
            getTimeout: () => scanDevice.config('interpolationSpeed'), device: scanDevice
        }))
            .addTimer(new AGpsStateEmitterTimer({
            getTimeout: () => scanDevice.config('gpsStateInterval'), device: scanDevice
        }))
            .addTimer(new AGpsBufferTimer({
            getTimeout: () => scanDevice.config('gpsBufferInterval'), device: scanDevice
        }))
            .addTimer(new ADetectionEmitterTimer({
            getTimeout: () => scanDevice.config('detectInterval'), detectCallback: () => this.detectVehicle()
        }))
            .addTimer(new ACamStateEmitterTimer({
            getTimeout: () => scanDevice.config('camStateInterval'), device: scanDevice
        }));
        this.observeWaySegments();
        this.handleMapEvents();
        $('.loading-page-init').fadeOut();
    }
    onMapClicked(e) {
        if (this.StartPosition.lat == 0.0 && this.StartPosition.lng == 0.0) {
            this.StartPosition.lat = e.latLng.lat();
            this.StartPosition.lng = e.latLng.lng();
            var marker = new google.maps.Marker({
                position: e.latLng,
                title: "Start",
                icon: {
                    url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png"
                },
                map: this.map
            });
            this.markers.push(marker);
        }
        else if (this.EndPosition.lat == 0.0 && this.EndPosition.lng == 0.0) {
            this.EndPosition.lat = e.latLng.lat();
            this.EndPosition.lng = e.latLng.lng();
            var marker = new google.maps.Marker({
                position: e.latLng,
                title: "Start",
                icon: {
                    url: "http://maps.google.com/mapfiles/ms/icons/green-dot.png"
                },
                map: this.map
            });
            this.markers.push(marker);
        }
        else {
            for (let i = 0; i < this.markers.length; i++) {
                this.markers[i].setMap(null);
            }
            this.markers = [];
            this.StartPosition = { lat: 0.0, lng: 0.0 };
            this.EndPosition = { lat: 0.0, lng: 0.0 };
            // this.route?.setMap(null)
        }
        if (this.StartPosition.lat != 0.0 && this.StartPosition.lng != 0.0
            && this.EndPosition.lat != 0.0 && this.EndPosition.lng != 0.0) {
            Loading.waitForPromises(this.getRouteWaySegments(this.StartPosition, this.EndPosition)).then((response) => {
                console.log(response);
                if (!response) {
                    return;
                }
                for (let id of response.WaySegmentIds) {
                    let absId = Math.abs(id);
                    if (this.waySegmentsMap.hasOwnProperty(absId)) {
                        google.maps.event.trigger(this.waySegmentsMap[absId], 'click');
                    }
                }
            }).catch(AError.handle);
        }
    }
    getRouteWaySegments(_StartPosition, _EndPosition) {
        const request = {
            "FromLat": _StartPosition.lat,
            "FromLon": _StartPosition.lng,
            "ToLat": _EndPosition.lat,
            "ToLon": _EndPosition.lng,
        };
        // requestService.send()
        return new Promise((resolve) => {
            CCCClient.SendMessage("RouteWaySegmentsRequest", 1, request, 0, {
                Type: "ControlCenter",
                IndexNumber: 1,
                CustomerNumber: CCCClient.NodeCustomerNumber,
                ProjectNumber: CCCClient.NodeProjectNumber //CCCClient.NodeProjectNumber
            });
            let hasResponse = false;
            Events.once(`RouteWaySegmentsResponse`, response => {
                hasResponse = true;
                resolve(response);
            });
            sleep(5000).then(async (_) => {
                if (hasResponse === false) {
                    const events = Alerts.show({
                        title: ALERT_TITLES.Warning,
                        content: await Translate.get('RouteRequest failed')
                    });
                    resolve(false);
                }
            });
        });
    }
    detectVehicle() {
        try {
            if (this.selectNewParkingSpace()) {
                this.broadcastDetection();
            }
        }
        catch (err) {
            AError.handle(err);
        }
    }
    broadcastDetection() {
        const DetectionGps = this.scanDevice.detectionIndicator.getPosition()?.toJSON() || this.scanDevice.pos;
        const GpsVehicleBounds = AGeoUtils.rotateDetectionBounds(DetectionGps, this.scanDevice.detectionIndicator);
        const data = {
            ScanDeviceGps: this.scanDevice.pos,
            DetectionGps: DetectionGps,
            GpsVehicleBounds: GpsVehicleBounds
        };
        AGeoUtils.parsePolgonUsingBounds(GpsVehicleBounds, { parseToMap: true });
        // Events.once('ImageRequest', (Data) => HandleImageRequest(Data))
        sendFakeDetection(data, this.scanDevice.config());
        injectFormValues(this.$form, {
            detectionIndex: this.scanDevice.config('detectionIndex')
        }, false);
    }
    selectNewParkingSpace() {
        const { currentRouteItem } = this.scanDevice;
        if (!currentRouteItem.waySegment) {
            console.log(`Current route item doesn't have a waySegment!`);
            return false;
        }
        // Key 1 = WaySegmentId, key 2 = WaySegmentSide
        // Value = list of SplitParkingSpaceIds
        const waySegmentId = currentRouteItem.waySegment.data.GeoId;
        if (this.hasParkingSpaces) {
            if (!this.waySegmentToSideSplitParkingSpaceMap.hasOwnProperty(waySegmentId)) {
                console.log(`Current route item doesn't have any parkingspaces!`);
                return false;
            }
            const sides = this.waySegmentToSideSplitParkingSpaceMap[waySegmentId];
            const allSplitParkingSpaceIds = Object.values(sides).flat();
            const id = allSplitParkingSpaceIds[Math.floor(Math.random() * allSplitParkingSpaceIds.length)];
            const ps = this.splitParkingSpacesDataMap[id];
            if (!this.scanDevice.detectionIndicator.hasOwnProperty('data')) {
                this.scanDevice.detectionIndicator.data = {};
            }
            this.scanDevice.detectionIndicator.data.target = ps;
            this.scanDevice.detectionIndicator.setPosition(AGeoUtils.getRandomPointInside(ps));
        }
        else {
            this.scanDevice.detectionIndicator.setPosition(this.scanDevice.pos);
        }
        return true;
    }
    convertToMap(arrayOfPolyLines, keyMapper, valueMapper) {
        const output = {};
        if (valueMapper) {
            arrayOfPolyLines.map((polyline) => {
                const key = keyMapper(polyline);
                output[key] = valueMapper(polyline);
            });
        }
        else {
            arrayOfPolyLines.map((polyline) => {
                const key = keyMapper(polyline);
                output[key] = polyline;
            });
        }
        return output;
    }
    get $form() {
        return $(`[tabview="setup"]`).find('form');
    }
    getFormData($form) {
        const $f = $form.is('form') ? $form : $form.find('form');
        const formData = extractFormData($f);
        return Object.assign({}, this.scanDevice.getConfigurableOptions(), formData);
    }
    setFormData($form, formData) {
        const data = Object.assign({}, this.scanDevice.getConfigurableOptions(), formData);
        injectFormData($form, data);
    }
    handleInterfaceEvents() {
        const $form = this.$form;
        $form.on('submit', (e) => e.preventDefault());
        const $inputs = $form.find(':input');
        $inputs.on('change keyup', (e) => {
            e.preventDefault();
            e.stopPropagation();
            const formData = this.getFormData($form);
            this.scanDevice.setConfigurableOptions(formData);
        });
        $('#loadProfileBtn').on('click', () => {
            const profileName = ($('#loadProfileText').val() || '');
            resetDetectionIndex();
            this.loadProfile(profileName);
            $('#saveProfileText').val(profileName);
        });
        $('#saveProfileBtn').on('click', () => {
            const profileName = ($('#saveProfileText').val() || '');
            resetDetectionIndex();
            this.saveProfile(profileName);
            this.redrawProfileOptions(profileName);
        });
        const $startScanning = $('#scanToggle #start');
        const $stopScanning = $('#scanToggle #stop');
        const $btnArr = [$startScanning, $stopScanning];
        $btnArr.map($startOrStop => $startOrStop.on('click', () => $btnArr.map($btn => $btn.toggleClass('hidden', $btn.is($startOrStop)))));
        $startScanning.on('click', () => this.scanDevice.startBroadcasting());
        $stopScanning.on('click', () => this.scanDevice.stopBroadcasting());
        this.redrawProfileOptions();
    }
    redrawProfileOptions(selectedProfileName) {
        const profiles = this.loadProfiles();
        $('#loadProfileText').html(/*html*/ `
      ${Object.keys(profiles).sort((a, b) => {
            return (profiles[b].saveTimeStamp || 0) - (profiles[a].saveTimeStamp || 0);
        }).map(profileName => {
            const attr = (selectedProfileName === profileName) ? 'selected="selected"' : '';
            return (`<option ${attr}>${profileName}</option>`);
        }).join('')}
    `);
    }
    loadProfiles() {
        const preferences = AEngine.get(APreferenceService);
        const profiles = preferences.load('profiles', {});
        return profiles;
    }
    loadProfile(profileName) {
        const preferences = AEngine.get(APreferenceService);
        const profiles = preferences.load('profiles', {});
        if (!profiles.hasOwnProperty(profileName)) {
            return;
        }
        const profile = profiles[profileName];
        this.scanDevice.reset();
        for (let id of profile.ids) {
            if (this.waySegmentsMap.hasOwnProperty(id)) {
                google.maps.event.trigger(this.waySegmentsMap[id], 'click', {
                    domEvent: new MouseEvent('click'),
                    latLng: new google.maps.LatLng(AGeoUtils.calcCenter(this.waySegmentsMap[id]))
                });
            }
        }
        this.setFormData(this.$form, profile);
        // TODO: Replace setFormData with AForm.injectFormData
        // AForm.injectFormData(this.$form, {
        //   formData: Object.assign(this.scanDevice.getConfigurableOptions(), profile)
        // })
        this.focusOnRoad(profile.ids[0]);
    }
    saveProfile(profileName) {
        const preferences = AEngine.get(APreferenceService);
        const profiles = preferences.load('profiles', {});
        if (profileName && profileName.length) {
            const newProfile = {
                saveTimeStamp: Date.now(),
                ...this.scanDevice.getConfigurableOptions(),
                ids: this.scanDevice.waysegments.map(v => v.data.GeoId)
            };
            profiles[profileName] = newProfile;
            preferences.save('profiles', profiles);
        }
    }
    delProfile(profileName) {
        const preferences = AEngine.get(APreferenceService);
        const profiles = preferences.load('profiles', {});
        if (profileName && profileName.length) {
            if (profiles.hasOwnProperty(profileName)) {
                delete profiles[profileName];
            }
            preferences.save('profiles', profiles);
        }
    }
    handleMapEvents() {
        this.splitParkingSpaces.map(parkingSpace => {
            google.maps.event.addListener(parkingSpace, "click", (ref) => {
                console.log({ parkingSpace, ref });
            });
        });
        // this.waySegments.map(wayseg => {
        //   google.maps.event.addListener(wayseg, "click", (event?: AMapEvent) => {
        //     event?.stop()
        //   })
        // })
    }
    onGeoWaySegmentClick(event) {
        // @ts-ignore
        const wayseg = this;
        const addedToRoute = PageScript.scanDevice.toggleWaysegment(wayseg);
        wayseg.setOptions({ strokeColor: (addedToRoute) ? WAYSEGMENT_HIGHLIGHT : WAYSEGMENT_GREY });
    }
    observeWaySegments() {
        const $tbody = $('#routeTable tbody');
        this.scanDevice.listenForNextWaysegment(() => {
            $tbody.find('tr[uid]').toArray().map(tr => $(tr)).map(($tr, i) => {
                $tr.toggleClass('active', this.scanDevice.currentRouteItem.selectionIndex === i);
            });
        });
        this.scanDevice.listenForNextStep(() => {
            $tbody.html(this.scanDevice.waysegments.map((waysegment) => {
                return waysegment ? ( /*html*/`
          <tr uid="${waysegment.data.GeoId}">
            <td>${waysegment.data.GeoId} ${waysegment.data.Name}</td>
          </tr>
        `) : '';
            }).join(''));
            $tbody.find('tr[uid]').toArray().map(tr => $(tr)).map(($tr, i) => {
                $tr.toggleClass('active', this.scanDevice.currentRouteItem.selectionIndex === i);
                $tr.on('click', (e) => {
                    this.focusOnRoad(Number($tr.attr('uid')));
                });
            });
        });
    }
    focusOnRoad(mapKey) {
        const waySegment = this.waySegmentsMap[mapKey];
        const path = waySegment.getPath();
        const latLng = path.getAt(Math.floor(path.getLength() / 2));
        console.log(latLng.toJSON());
        this.map.setCenter(latLng);
    }
}
export function css() {
    return ( /*html*/`
    <style>
      @font-face {
        font-family: Kenteken;
        src: url('/font/Kenteken.ttf') format('truetype');
        /*
      src: url('/font/Kenteken.eot'); /* IE9 Compat Modes */
        src: url('/font/Kenteken.eot?#iefix') format('embedded-opentype'),
          /* IE6-IE8 */
          url('/font/Kenteken.woff') format('woff'),
          /* Pretty Modern Browsers */
          url('/font/Kenteken.ttf') format('truetype'),
          /* Safari, Android, iOS */
          url('/font/Kenteken.svg#svgFontName') format('svg');
        /* Legacy iOS */
        font-weight: normal;
      }
      .aci-tabs {
        background: #f1f0f0;
      }
      [tabview] {
        height: 100%;
        padding-top: 15px;
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 15px;
        overflow-y: auto;
      }
      .aci-tabs {
        /*height: 56px; */
      }
      .views {
        height: calc(100% - 56px)
      }
    </style>
  `);
}
export function render() {
    return ( /*html*/`
    <div class="wrapper lg columns col-gapless">
      <div class="column col-4" style="background: #f1f0f0; height: 100%">
        <div class="hidden preload" style="font: 40px opensans"></div>
        <div class="hidden preload" style="font: 30px Kenteken"></div>
      
        <div class="aci-tabs tabs-flex tabs-sticky tabs-fixed-md tabs-xl v-zero sharp-border" tabgroup="sideview">
          <button style="flex: 0 1 50%;" class="aci-tab active" tab="setup">
            <i class="fa-solid fa-car-wrench"></i>
            <span>Setup</span>
          </button>
          <button style="flex: 0 1 50%;" class="aci-tab" tab="route">
            <i class="fa-solid fa-route"></i>
            <span>Route</span>
          </button>
        </div>
        <div class="views" style="position: relative">
          <div class="loading-page-init">
            <div class="loading-circular size-xxl text-primary" style="--fa-animation-duration: 1s; --fa-thickness: 0.3rem; height: 100%;"></div>
          </div>
          <div class="column col-12" tabgroup="sideview" tabview="setup">
            <div style="height: calc(100% - 36px - 36px - 36px - 33px); overflow: auto;">
              ${genForm({
        detectionPrefix: {
            type: 'text',
            title: 'detectionPrefix',
            width: 'col-6'
        },
        detectionIndex: {
            type: 'number',
            title: 'detectionIndex',
            value: 1,
            width: 'col-6'
        },
        inheritStreetMaxSpeed: {
            type: "checkbox",
            title: 'inheritStreetMaxSpeed',
        },
        targetSpeed: {
            type: 'number',
            title: 'targetSpeed',
        },
        detectInterval: {
            type: 'number',
            title: 'detectInterval (milliseconds)',
        },
        camStateInterval: {
            type: 'number',
            title: 'camStateInterval (milliseconds)',
        },
        gpsStateInterval: {
            type: 'number',
            title: 'gpsStateInterval (milliseconds)',
        },
        gpsBufferInterval: {
            type: 'number',
            title: 'gpsBufferInterval (milliseconds)',
        },
        centerVehicle: {
            type: 'checkbox',
            title: 'Keep Vehicle Centered',
            width: 'col-6'
        },
    }, {
        valueMapper(propertyName) {
            const template = AScanDevice.defaultConfigurableOptions();
            if (!template.hasOwnProperty(propertyName)) {
                throw new Error(`Couldn't generate form because property "${propertyName} couldn't be found!"`);
            }
            return template[propertyName];
        },
        cls: 'columns col-gapless mb-1'
    })}
            </div>
            
            <div class="divider"></div>
            <div class="input-group mb-1">
              <select id="loadProfileText" class="form-select form-input full-width col-6">
              </select>
              <button id="loadProfileBtn" class="btn btn-grey full-width input-group-btn col-6">Load Profile</button>
            </div>
            
            <div class="input-group mb-1">
              <input id="saveProfileText" type="text" class="form-input full-width col-6" placeholder="..." />
              <button id="saveProfileBtn" class="btn btn-grey full-width input-group-btn col-6">Save Profile</button>
            </div>
            
            <div id="scanToggle" class="input-group">
              <button id="start" class="btn btn-primary full-width input-group-btn col-12">Start Scanning</button>
              <button id="stop" class="btn btn-error full-width input-group-btn col-12 hidden">Stop Scanning</button>
            </div>
          </div>
          <div class="column col-12" tabgroup="sideview" tabview="route">
            <table id="routeTable" class="styled-table grid-like full-width">
              <thead>
                <tr class="head noselect">
                  <th>Route</th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="column col-8" style="height: 100%; background: #f8f9fa">
        <div id="map" class="aci-map"></div>
      </div>
    </div>
  `);
}
