import { ALoopTimer } from '../classes/ALoopTimer.js';
import { AFormatDateTimezone } from '../utils/tools.js';
export class ALoadTimebar {
    static async run() {
        // const bodyClass = AEngine.get()
        const timer = new ALoopTimer(() => $('.timebar').text(AFormatDateTimezone(new Date())), {
            timeout: 1000, loopLifeCycle: 'CONTINUOUS'
        });
        timer.start();
        $('.timebar').removeClass('hidden');
    }
}
