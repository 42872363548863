import { ACamStateEmitterTimer } from "../../timers/ACamStateEmitterTimer.js";
import { distance, genMarker } from "../../utils/scanauto-geo-tools.js";
import { WAYSEGMENT_GREY } from "../../utils/scanauto-tools.js";
import { AGeoUtils } from "../../core/maps/AGeoUtils.js";
import { AFakeGpsDeviceBase } from "./AFakeGpsDeviceBase.js";
export class AScanDevice extends AFakeGpsDeviceBase {
    get speed() {
        if (!this.isRunning()) {
            return 0.0;
        }
        let val;
        if (this.cfg.speedDeviation !== undefined) {
            val = (this.cfg.targetSpeed - this.cfg.speedDeviation) + (Math.random() * this.cfg.speedDeviation * 2);
        }
        else {
            val = this.cfg.targetSpeed;
        }
        return Math.max(val, 0);
    }
    constructor(options) {
        super(options);
        this.cfg = AScanDevice.defaultConfigurableOptions();
        this.setConfigurableOptions(options);
        this.routeItemIndex = 0;
        this.selected = [];
        this.route = [];
        this.detectionIndicator = genMarker({
            position: new google.maps.LatLng(0, 0),
            map: PageScript.map,
        });
    }
    static defaultConfigurableOptions() {
        return {
            detectionIndex: 1,
            inheritStreetMaxSpeed: false,
            detectInterval: 1000,
            targetSpeed: 30,
            speedDeviation: 5,
            interpolationSpeed: 50,
            gpsStateInterval: 5000,
            gpsBufferInterval: 100,
            detectionPrefix: globalThis.prefix || 'TEST',
            centerVehicle: false,
            camStateInterval: 500,
        };
    }
    setConfigurableOptions(options) {
        Object.keys(this.cfg).map(key => {
            if (options[key] !== undefined) {
                this.cfg[key] = options[key];
            }
        });
        this.reloadTimeouts();
    }
    getConfigurableOptions() {
        return this.cfg;
    }
    recalcRoute() {
        this.route = this.calcRoute();
        Events.tryInvoke('SELECTION_CHANGED');
    }
    get waySegmentToSideSplitParkingSpaceMap() {
        return PageScript.waySegmentToSideSplitParkingSpaceMap;
    }
    calcRoute() {
        // const { data }: { data: { Active, Index, Name, Attributes } } = line as any
        const waySegmentPoints = this.selected.map((entry, i) => {
            const sides = Object.keys(this.waySegmentToSideSplitParkingSpaceMap[entry.data.GeoId] || {}).map(key => Number(key));
            let path = AGeoUtils.convertPathToArray(entry.getPath());
            return path.map((latLng) => ({
                latLng,
                selectionIndex: i,
                maxSpeed: Number(entry.data.Attributes.maxspeed) || Number.NaN,
                waySegment: entry,
                sides: sides,
                deadEnd: (sides.length === 1 && sides[0] === 0)
            }));
        });
        if (waySegmentPoints.length === 0) {
            return [];
        }
        // Return sorted route
        return waySegmentPoints.reduce((prev, curr, i) => {
            if (curr.length > 1) {
                const prevFirst = prev[0], prevLast = prev[prev.length - 1];
                const currFirst = curr[0], currLast = curr[curr.length - 1];
                const distanceToFirst = distance(prevLast.latLng, currFirst.latLng);
                const distanceToLast = distance(prevLast.latLng, currLast.latLng);
                if (distanceToFirst > distanceToLast) {
                    curr.reverse();
                }
                if (currFirst.deadEnd === true || currLast.deadEnd === true) {
                    curr = curr.concat([...curr].reverse());
                }
            }
            return prev.concat(curr);
        });
    }
    /**
     * If the route doesn't contain the waysegment, it'll add it,
     * Otherwise the waysegment will be removed from the route collection
     * @returns whether the waysegment has been added to the route
     */
    toggleWaysegment(waysegment) {
        const index = this.selected.indexOf(waysegment);
        const addedToRoute = (index === -1);
        if (addedToRoute) {
            this.selected.push(waysegment);
        }
        else {
            this.selected.splice(index, 1);
        }
        this.recalcRoute();
        return addedToRoute;
    }
    resetWaysegments() {
        this.selected.map((waysegment) => {
            waysegment.setOptions({ strokeColor: WAYSEGMENT_GREY });
        });
        this.selected = [];
        this.recalcRoute();
    }
    reset() {
        this.resetWaysegments();
        this.routeItemIndex = 0;
    }
    get waysegments() {
        return this.selected;
    }
    get routeItems() {
        return this.route;
    }
    get currentRouteItem() {
        if (this.route.length === 0) {
            return {
                latLng: this.pos,
                selectionIndex: 0,
                maxSpeed: Number.NaN,
                sides: [-1, 1],
                deadEnd: false
            };
        }
        return this.route[this.routeItemIndex % this.route.length];
    }
    get targetPos() {
        return this.currentRouteItem.latLng;
    }
    selectNextWaysegment(increment) {
        this.routeItemIndex += increment;
        Events.tryInvoke('SCANDEVICE_POS_INDEX_CHANGED');
        if (this.routeItemIndex > 0 && (this.routeItemIndex % this.route.length) === 0) {
            Events.tryInvoke('SCANDEVICE_ROUTE_FINISHED');
            this.stopBroadcasting();
            this.startBroadcasting();
        }
    }
    listenForNextWaysegment(callbackFn) {
        Events.on('SCANDEVICE_POS_INDEX_CHANGED', callbackFn);
    }
    listenForNextStep(callbackFn) {
        Events.on('SELECTION_CHANGED', callbackFn);
    }
    setStatistics(statistics) {
        this.statistics = statistics;
    }
    printStatistics() {
        const { simple, precise, distanceToTarget } = this.statistics;
        console.log(`
                \t\t\tSimple   \tPrecise
      Velocity: \t\t\t${simple.kmPerHour.toPrecision(5)}\t\t${precise.kmPerHour.toPrecision(5)}
      Distance: \t\t\t${simple.distanceInMeters.toPrecision(5)}\t\t${precise.distanceInMeters.toPrecision(5)}
      DistanceToTarget: \t${distanceToTarget.toPrecision(5)}
      TargetSpeed: \t\t${this.config('targetSpeed').toPrecision(5)}
    `);
    }
    teleportToStart() {
        this.position = this.targetPos;
    }
    reloadTimeouts() {
        this.timers.map(timer => {
            // Reload all timeouts using the ScanDevice.cfg getters
            timer.reloadTimeout();
        });
    }
    startBroadcasting() {
        this.teleportToStart();
        this.reloadTimeouts();
        super.startBroadcasting();
    }
    stopBroadcasting() {
        super.stopBroadcasting();
        this.getTimer(ACamStateEmitterTimer).sendCamStateToServer(false);
    }
}
AScanDevice.MIN_DISTANCE_FOR_NEXT_WAYPOINT = 5;
AScanDevice.MAX_DISTANCE_FOR_DETECTION = 30;
