export class SAVE_IDS {
    static get MENU_OPEN() { return 'MENU-OPEN'; }
    static get BG_COLOR() { return 'BACKGROUND-COLOR'; }
    static get HIDE_IMAGE_VIEW() { return 'IMAGE-VIEW-CLOSED'; }
}
export class APreferenceService {
    constructor() {
        Object.defineProperty(window, 'Preferences', {
            get: () => this
        });
    }
    /**
     * Same functionality as save(key, object)
     */
    set(key, object) {
        this.save(key, object);
    }
    save(key, object) {
        const json = JSON.stringify(object);
        localStorage.setItem(key, json);
    }
    /**
     * Same functionality as load(key, object)
     */
    get(key) {
        return this.load(key);
    }
    load(key, defaultValue = null) {
        const item = localStorage.getItem(key);
        return (item !== null) ? JSON.parse(item) : defaultValue;
    }
}
