export function addTabListeners($tabs) {
    $tabs.removeWhiteSpaces();
    const $children = $tabs.find('.aci-tab');
    $children.each((i, tab) => {
        const $singleTab = $(tab);
        $singleTab.on('click', (e) => {
            $children.removeClass('active');
            $singleTab.addClass('active');
            const tabgroup = $tabs.attr('tabgroup');
            const tabview = $singleTab.attr('tab');
            $(`[tabgroup="${tabgroup}"][tabview]`).hide();
            const $tabToView = $(`[tabgroup="${tabgroup}"][tabview="${tabview}"]`);
            $tabToView.show();
            $tabs.trigger('ACI_TABS_CHANGED', { tabview });
            Events.tryInvoke(`ACI_TABS_CHANGED->${tabgroup}`, { tabgroup, tabview, $tabview: $tabToView });
            Events.tryInvoke(`ACI_TABS_CHANGED->${tabgroup}->${tabview}`, { tabgroup, tabview, $tabview: $tabToView });
        });
    });
    const $found = $tabs.find('.aci-tab.active');
    $found.trigger('click');
}
export function addTabListenersFind($container) {
    const $aciTabs = $container.find('.aci-tabs');
    if ($aciTabs.length > 0) {
        $(`[tabview]`).hide();
        $aciTabs.each((i, tabs) => {
            addTabListeners($(tabs));
        });
    }
}
