import { AError } from "../../classes/AError.js";
import { EVENTS } from "../../services/AEventService.js";
import { AFormatDate, _getEle$ } from "../../utils/tools.js";
import { AEngine } from "../AEngine.js";
import { AInfoWindowHelper } from "../form/AInfoWindowHelper.js";
import { ATableBuilder2, ATableKey } from "../form/ATableBuilder2.js";
import { ATableFormatter } from "../form/ATableFormatter.js";
import { getCenterAny } from "./mapToolsTmp.js";
const PRIORITY_ID = 'TableSummary';
const FULLTABLE_ID = 'TableFull';
export var InfoWindowType;
(function (InfoWindowType) {
    InfoWindowType[InfoWindowType["custom"] = 0] = "custom";
    InfoWindowType[InfoWindowType["google"] = 1] = "google";
})(InfoWindowType || (InfoWindowType = {}));
let purgatoryService;
export class ABasePurgatoryService {
    constructor() {
        this.serviceName = AEngine.toServiceName(new.target);
        purgatoryService = this;
    }
    createDoubleTableRes({ response, tableFormatter, greyOutFields }) {
        const TableBuilderPriority = new ATableBuilder2({ tableId: PRIORITY_ID, tableFormatter, greyOutFields });
        const TableBuilder = new ATableBuilder2({ tableId: FULLTABLE_ID, tableFormatter, greyOutFields });
        for (let c = 0; c < response.Columns.length; c++) {
            const column = response.Columns[c];
            const columnTranslated = response.ColumnsTranslated[c];
            const tableKey = new ATableKey(column, columnTranslated);
            const columnOptions = tableFormatter.getColumnFormatOptions(tableKey); // InfoWindowHelper.getColumnSettings(column)
            const insertVal = [tableKey, response.Rows[0][c]];
            if (columnOptions.isPriority === true) {
                TableBuilderPriority.insert(insertVal);
            }
            TableBuilder.insert(insertVal);
        }
        return [TableBuilderPriority, TableBuilder];
    }
    /**
     * @TWO_EX
     * CREATES TWO TABLES (2 OBJECT EXPORT)
     * Infowindow with "extra information" button
     */
    async createDoubleTableObj(verifyRequest, formatter) {
        // const fieldDefinitions = AConfig.get('infoWindows.default.definitions', {})
        // const sorting = AConfig.get('infoWindows.default.order', [])
        const tableFormatter = formatter ?? new ATableFormatter();
        // const tableFormatter = default_tableformatter()
        const TableBuilderPriority = new ATableBuilder2({ tableId: PRIORITY_ID, tableFormatter, greyOutFields: false });
        const TableBuilder = new ATableBuilder2({ tableId: FULLTABLE_ID, tableFormatter, greyOutFields: false });
        const keys = Object.keys(verifyRequest);
        const translations = await Loading.waitForPromises(Translate.get(keys));
        for (let column of keys) {
            const tableKey = new ATableKey(column, translations[column]);
            const columnOptions = tableFormatter.getColumnFormatOptions(tableKey); //InfoWindowHelper.getColumnSettings(column)
            const insertVal = [tableKey, verifyRequest[column]];
            if (columnOptions.isPriority === true) {
                TableBuilderPriority.insert(insertVal);
            }
            TableBuilder.insert(insertVal);
        }
        return { TableBuilderPriority, TableBuilder };
    }
    async createSingleTable({ data, tableFormatter, greyOutFields = true }) {
        const builder = new ATableBuilder2({ tableId: FULLTABLE_ID, greyOutFields })
            .setFormatter(tableFormatter);
        const keys = Object.keys(data);
        const translations = await Loading.waitForPromises(Translate.get(keys));
        for (let column of keys) {
            const insertVal = [new ATableKey(column, translations[column]), data[column]];
            builder.insert(insertVal);
        }
        return builder;
    }
    /**
    * @ONE_EX_STR
    * @where [ADetectionService.findHistory()]
    * @param {*} response
    * @param {*} options
    */
    responseToTable(response, options) {
        const { index, excluded } = Object.assign({ excluded: [] }, options);
        let { Rows, Columns, ColumnsTranslated } = response;
        let output = [];
        if (index !== null && index !== undefined) {
            Rows = [Rows[index]];
        }
        for (let i = 0; i < Rows.length; i++) {
            const TableBuilder = new ATableBuilder2();
            for (let c = 0; c < Columns.length; c++) {
                if (excluded.includes(Columns[c])) {
                    continue;
                }
                const insertVal = [new ATableKey(Columns[c], ColumnsTranslated[c]), Rows[i][c]];
                TableBuilder.insert(insertVal);
            }
            output.push(TableBuilder);
        }
        return output.map((tb, i) => tb.build({ visible: (i === 0) })).join('');
    }
    cacheMarkerColor(marker) {
        PageScript.__cachedMarker = marker;
        PageScript.__cachedColors = {
            fill: marker.fillColor,
            outline: marker.strokeColor
        };
    }
    revertCachedMarkerColor() {
        if (!PageScript)
            return;
        const { __cachedMarker, __cachedColors } = PageScript;
        if (!__cachedMarker || !__cachedColors)
            return;
        __cachedMarker.setOptions({
            fillOpacity: 0.35
        });
        delete PageScript.__cachedMarker;
        delete PageScript.__cachedColors;
    }
    applySelectedColor(marker) {
        try {
            purgatoryService.revertCachedMarkerColor();
            purgatoryService.cacheMarkerColor(marker);
        }
        catch (err) {
            AError.handle(err);
        }
        marker.setOptions({
            fillOpacity: 1
        });
    }
    /**
    * Shows the InfoWindow for the clicked Team member
    */
    clickTeamMember() {
        const marker = this;
        // @ts-ignore
        const { data } = marker;
        if (!data)
            return;
        console.log('marker', marker);
        console.log('data', data);
        if (data && data.Gps && data.Gps.GpsTime) {
            Object.assign(data, {
                LastUpdated: AFormatDate(new Date(data.Gps.GpsTime))
            });
        }
        // TODO: Fix implementation
        return purgatoryService.buildAndShowInfoWindowLegacy({
            data: data,
            marker: this,
            sorting: [],
            tableFormatter: new ATableFormatter() // team_tableformatter(),
        });
    }
    /**
     * @deprecated
     * Shows the InfoWindow for the clicked polygon
     * Use ACoreMapService.onGeoClick
     */
    async clickPolygon(event) {
        AEngine.log('ABasePurgatoryService.clickPolygon(event?: %cMouseEvent%n) %g event=', event);
        // "This" is in the context of a marker
        // @ts-ignore
        if (!this.data)
            return;
        // @ts-ignore
        console.log('this', this, 'data', this.data);
        // @ts-ignore
        const data = this.data;
        const { scale, area, id } = data;
        if (globalThis.mapHelperService?.geoObjects[scale] == undefined) {
            throw new Error(`No map scale selected!`);
        }
        let copied = Object.assign({}, globalThis.mapHelperService.geoObjects[scale][id]);
        let windowOptions = {};
        if (PageScript.overridePolygonInfoWindow) {
            windowOptions = PageScript.overridePolygonInfoWindow({ ref: this, copied, data });
            purgatoryService.buildAndShowInfoWindowLegacy({
                marker: this,
                data: (windowOptions.combineResults === true) ? { ...windowOptions.data, ...copied } : windowOptions.data,
                tableFormatter: (windowOptions.combineResults === true) ? new ATableFormatter() : windowOptions.tableFormatter,
                greyOutFields: true,
                sorting: Object.keys(windowOptions.data),
            });
        }
        else {
            purgatoryService.buildAndShowInfoWindowLegacy({
                marker: this,
                data: copied,
                tableFormatter: new ATableFormatter(),
                greyOutFields: true,
                sorting: [],
            });
        }
    }
    async buildAndShowInfoWindowLegacy({ marker, data, sorting, tableFormatter, greyOutFields = false }) {
        const table = await this.createSingleTable({
            data: data,
            tableFormatter: tableFormatter,
            greyOutFields
        });
        const iw = this.showInfoWindowLegacy({
            marker: marker,
            content: table.build({ sorting }),
        });
        this.focusOnMarker(marker);
        return iw;
    }
    async buildAndShowInfoWindow({ parent, marker, data, sorting, tableFormatter, greyOutFields = false }) {
        const table = await this.createSingleTable({
            data: data,
            tableFormatter: tableFormatter || new ATableFormatter(),
            greyOutFields
        });
        this.showInfoWindowSingleCustom({
            parent: parent || '.aci-map',
            table: table.build({ sorting }),
            options: { buttons: [] }
        });
        this.focusOnMarker(marker);
    }
    async buildAndShowInfoWindowCustom({ parent, marker, data, sorting, tableFormatter, greyOutFields = false }) {
        const tables = this.createDoubleTableRes({
            response: data,
            tableFormatter: tableFormatter || new ATableFormatter(),
            greyOutFields
        }).map(t => t.build({ sorting }));
        const { elements } = await this.showInfoWindowDoubleCustom({
            parent: parent || '.aci-map',
            tables: tables,
            options: {
                buttons: [
                    {
                        id: 'info-history',
                        text: 'History'
                    }
                ]
            }
        });
        elements.map($ele => $ele.on('click', _ => {
            let [DetectionDeviceId, DetectionId] = marker.Identifier.split('_');
            globalThis.detectionService.findHistory({ DetectionDeviceId, DetectionId });
        }));
        this.focusOnMarker(marker);
    }
    async showInfoWindowSingleCustom({ parent, table, options }) {
        purgatoryService.closeInfoWindow();
        const $parent = _getEle$(parent);
        const { buttons, height } = Object.assign({ buttons: [] }, options);
        const elementsToFind = ['#info-toggle-data'];
        buttons.map((button) => elementsToFind.push(`#${button.id}`));
        let $sideview = $(`
      <div class="sideview sideview-map custom-scroll">
        <div class="iw-header">
          <i class="exit fa-solid fa-xmark fa-fw"></i>
        </div>
        <div class="table-content">
          ${table}
        </div>
        <div class="btn-group btn-group-block">
          ${buttons.map((button) => (`<button id="${button.id}" class="btn btn-white btn-sm">${button.text}</button>`))}
        </div>
      </div>
    `);
        $sideview.find('.exit').on('click', _ => purgatoryService.closeInfoWindow());
        purgatoryService.createCloseHandler($sideview);
        $sideview.toggleClass('sideview-has-buttons', buttons.length > 0);
        const elements = elementsToFind.map(selector => $sideview.find(selector));
        AInfoWindowHelper.initDraggableWindow({
            $window: $sideview
        });
        $('#AjaxContent').append($sideview);
        const { left, top } = $parent.offset();
        $sideview.css({
            left,
            top,
            height: height ?? $sideview.attr('summary-height')
        });
        return { $window: $sideview, elements };
    }
    async showInfoWindowDoubleCustom({ parent, tables, options }) {
        purgatoryService.closeInfoWindow();
        const $parent = _getEle$(parent);
        const { buttons } = Object.assign({ buttons: [] }, options);
        const toTranslate = ['View all', 'View less'];
        buttons.map((button) => toTranslate.push(button.text));
        const elementsToFind = ['#info-toggle-data'];
        buttons.map((button) => elementsToFind.push(`#${button.id}`));
        const t = await Loading.waitForPromises(Translate.get(toTranslate));
        let $sideview = $(`
      <div class="sideview sideview-map custom-scroll">
        <div class="iw-header">
          <i class="exit fa-solid fa-xmark fa-fw"></i>
        </div>
        <div class="table-content">
          ${tables.join('')}
        </div>
        <div class="btn-group btn-group-block">
          <button id="info-toggle-data" class="btn btn-white btn-sm">${t['View all']}</button>
          ${buttons.map((button) => (`<button id="${button.id}" class="btn btn-white btn-sm">${t[button.text]}</button>`))}
        </div>
      </div>
    `);
        $sideview.find('.exit').on('click', _ => purgatoryService.closeInfoWindow());
        purgatoryService.createCloseHandler($sideview);
        $sideview.toggleClass('sideview-has-buttons', buttons.length > 0);
        const elements = elementsToFind.map(selector => $sideview.find(selector));
        const [tablePriority, tableFull] = [$sideview.find(`#${PRIORITY_ID}`), $sideview.find(`#${FULLTABLE_ID}`)];
        const height = tablePriority.find('tr').length * 21;
        $sideview.attr('summary-height', height);
        $sideview.attr('full-height', height * 2);
        AInfoWindowHelper.createToggleButtonListeners({
            button: elements.shift(),
            tables: [tablePriority, tableFull],
            translations: t,
            callback: (full) => {
                $sideview.height($sideview.attr(full ? 'full-height' : 'summary-height'));
            }
        });
        AInfoWindowHelper.initDraggableWindow({
            $window: $sideview
        });
        $('#AjaxContent').append($sideview);
        const { left, top } = $parent.offset();
        $sideview.css({
            left,
            top,
            height: $sideview.attr('summary-height')
        });
        return { elements };
    }
    showInfoWindowLegacy({ marker, pos, content }) {
        purgatoryService.closeInfoWindow();
        const infow = new globalThis.InfoWindow();
        infow.setContent(content || '...');
        infow.setPosition(pos ? pos : getCenterAny(marker));
        infow.setOptions({
            pixelOffset: new google.maps.Size(0, -48)
        });
        infow.open(PageScript.map);
        purgatoryService.createCloseHandler(infow);
        return infow;
    }
    closeInfoWindow() {
        return Events.tryInvoke(EVENTS.INFOWINDOW_CLOSE);
    }
    createCloseHandler(infowindow) {
        Events.once(EVENTS.INFOWINDOW_CLOSE, (opt) => {
            const objToClose = infowindow;
            const { silent } = Object.assign({ silent: false }, opt);
            if (!silent) {
                purgatoryService.revertCachedMarkerColor();
            }
            if (infowindow instanceof jQuery) {
                objToClose.remove();
            }
            else {
                objToClose.close();
            }
        });
    }
    focusOnMarker(marker) {
        if (marker && globalThis.AConfig?.get('general.map.centerOnClick', false)) {
            const map = marker?.map || PageScript.map;
            map.setCenter(getCenterAny(marker));
            if (map.getZoom() < 11) {
                map.setZoom(20);
            }
        }
    }
}
