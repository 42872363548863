import { HUE_MAX, SV_MAX } from "./AColor.js";
import { AColorHSV } from "./AColorHSV.js";
export class AInterpolateService {
    constructor() { }
    /**
     * Interpolates between two colors.
     * @param {AColorHSV} a
     *  Starting color.
     * @param {AColorHSV} b
     *  Target color.
     * @param {Number} t
     *  How far should it interpolate, 0 being a, 1 being b.
     */
    colorHSV(a, b, t) {
        a = a.normalized;
        b = b.normalized;
        // Hue interpolation
        let h = 0;
        let d = b.h - a.h;
        if (a.h > b.h) {
            let temp = b.h;
            b.h = a.h;
            a.h = temp;
            let tempV = b.v;
            b.v = a.v;
            a.v = tempV;
            d = -d;
            t = 1 - t;
        }
        // 180deg
        if (d > 0.5) {
            a.h = a.h + 1; // 360deg
            h = (a.h + t * (b.h - a.h)) % 1; // 360deg
        }
        // 180deg
        if (d <= 0.5) {
            h = a.h + t * d;
        }
        // Interpolates the rest
        return new AColorHSV(h * HUE_MAX, // H
        (a.s + t * (b.s - a.s)) * SV_MAX, // S
        (a.v + t * (b.v - a.v)) * SV_MAX);
    }
    /**
     * Interpolates between two colors.
     * @param {AColorHSV} a
     *  Starting color.
     * @param {AColorHSV} b
     *  Target color.
     * @param {Number} chunkCount
     *  How many different colors should be returned
     */
    colorsHSV(a, b, chunkCount) {
        const colors = [];
        const step = 1 / Math.max(chunkCount - 1, 1);
        for (let i = 0; i < chunkCount; i++) {
            const clr = this.colorHSV(a, b, step * i).rgb.hexi;
            colors.push(clr);
        }
        colors.push(b.rgb.hexi);
        return colors;
    }
}
