import { EVENTS } from "./AEventService.js";
export class APermissionService {
    constructor() {
        Object.defineProperty(window, 'Permissions', {
            get: () => this
        });
    }
    autoInit() {
        Events.hardwire(EVENTS.PREFETCH, _ => {
            this.hideAciElements();
        });
    }
    fetchAllUsergroups(name) {
        const where = name ? `WHERE UserGroup='${name}'` : '';
        return requestService.query({
            Query: `SELECT UserGroup, UserGroup as UserGroupText FROM usergroups ${where}`,
            Name: 'FetchUsergroups',
            Language: Language,
            Translate: ["UserGroupText"]
        });
    }
    fetchAllvwUsergroups(name) {
        const where = name ? `WHERE UserGroup='${name}'` : '';
        return requestService.query({
            Query: `SELECT UserGroup, UserGroup as UserGroupText FROM vwusergroups ${where}`,
            Name: 'FetchvwUsergroups',
            Language: Language,
            Translate: ["UserGroupText"]
        });
    }
    fetchUsergroup(user) {
        return requestService.query({
            Query: `SELECT UserGroup FROM user_usergroups WHERE User=:User`,
            Name: 'FetchUsergroup',
            Params: {
                User: user
            }
        });
    }
    hasPermission(requiredIdMRs) {
        // const user = _.getUser()
        // if (!user) {
        //   return false
        // }
        // for (const group of user.UserGroups) {
        //   if (group.toLowerCase() === 'aci_admin') {
        //     return true
        //   }
        // }
        // for (const requiredIdMR of requiredIdMRs) {
        //   if (requiredIdMR != null && !user.id_mrs.includes(requiredIdMR)) {
        //     return false
        //   }
        // }
        return true;
    }
    hideAciElements() {
        // const $eles = $(`[usergroup="aci_admin"]`)
        // $eles.each((i, ele) => {
        //   if (!_.getUser().UserGroups.includes('aci_admin')) {
        //     $(ele).remove()
        //   }
        // })
    }
    getParent(id_mr) {
        for (const key in this.menuService.menu) {
            const item = this.menuService.menu[key];
            if (item.children && item.children.length) {
                for (const child of item.children) {
                    if (child.id_mr === id_mr) {
                        return item;
                    }
                }
            }
        }
        return null;
    }
    getMenuItem(id_mr) {
        let output = null;
        for (const menuItem of Object.values(this.menuService.menu)) {
            if (menuItem.id_mr == id_mr) {
                output = menuItem;
                break;
            }
            if (menuItem.children) {
                const child = this.findInChildren(menuItem.children, id_mr);
                if (child !== null) {
                    output = child;
                    break;
                }
            }
        }
        return output;
    }
    findInChildren(children, id_mr) {
        let output = null;
        for (const menuItem of children) {
            if (menuItem.id_mr == id_mr) {
                output = menuItem;
                break;
            }
        }
        return output;
    }
}
