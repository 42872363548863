import { TextEncoderLite } from "../core/text-encoder-lite.js";
export function stringify(val, depth, replacer, space) {
    depth = isNaN(+depth) ? 1 : depth;
    function _build(key, val, depth, o, a) {
        return !val || typeof val != 'object' ? val : (a = Array.isArray(val), JSON.stringify(val, function (k, v) { if (a || depth > 0) {
            if (replacer)
                v = replacer(k, v);
            if (!k)
                return (a = Array.isArray(v), val = v);
            !o && (o = a ? [] : {});
            o[k] = _build(k, v, a ? depth : depth - 1);
        } }), o || (a ? [] : {}));
    }
    return JSON.stringify(_build('', val, depth), null, space);
}
export function SetCookie(name, value, days, shareSubdomains) {
    let expires = '';
    if (days) {
        let date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "expires=" + date.toUTCString();
    }
    let domain = '';
    if (shareSubdomains === true) {
        const parentDomain = location.host.split('.').splice(1).join('.');
        domain = (parentDomain.endsWith('.scanacar.com')) ? `domain=${parentDomain}` : '';
    }
    const cookie = [
        `${name}=${value}`,
        `SameSite=Strict`,
        `path=/`,
        expires,
        domain
    ].filter(v => v != '').join(';');
    // const cookie = name + "=" + value + "; SameSite=Strict" + expires + "; path=/" + domain
    console.log('cookie', cookie);
    document.cookie = cookie;
}
export function GetCookie(name, fallback = (v) => v) {
    let nameEQ = name + "=";
    let parts = document.cookie.split(';');
    for (let i = 0; i < parts.length; i++) {
        let q = parts[i];
        while (q.charAt(0) == ' ')
            q = q.substring(1, q.length);
        if (q.indexOf(nameEQ) == 0)
            return q.substring(nameEQ.length, q.length);
    }
    return fallback(null);
}
export function AInputDate(d) {
    var yyyy = d.getFullYear();
    var mm = (d.getMonth() + 1);
    var dd = d.getDate();
    if (mm < 10)
        mm = '0' + mm;
    if (dd < 10)
        dd = '0' + dd;
    return yyyy + "-" + mm + "-" + dd;
}
export function AInputTime(d) {
    var hh = d.getHours();
    var mm = d.getMinutes();
    if (hh < 10)
        hh = '0' + hh;
    if (mm < 10)
        mm = '0' + mm;
    return hh + ":" + mm;
}
export function AInputTimeSeconds(d) {
    var hh = d.getHours();
    var mm = d.getMinutes();
    var ss = d.getSeconds();
    var zzz = d.getMilliseconds();
    if (hh < 10)
        hh = '0' + hh;
    if (mm < 10)
        mm = '0' + mm;
    if (ss < 10)
        ss = '0' + ss;
    if (zzz < 10)
        zzz = '00' + zzz;
    else if (zzz < 100)
        zzz = '0' + zzz;
    return hh + ":" + mm + ":" + ss + "." + zzz;
}
export function AFormatDateTimezone(d, s = " ", ds = "-", ts = ":", tzs = " ") {
    let Year = d.getFullYear();
    let Month = d.getMonth() + 1;
    let Day = d.getDate();
    let Hour = d.getHours();
    let Minute = d.getMinutes();
    let Second = d.getSeconds();
    if (Month < 10)
        Month = "0" + Month;
    if (Day < 10)
        Day = "0" + Day;
    if (Hour < 10)
        Hour = "0" + Hour;
    if (Minute < 10)
        Minute = "0" + Minute;
    if (Second < 10)
        Second = "0" + Second;
    let FormatedDateTime = Year + ds + Month + ds + Day + s + Hour + ts + Minute + ts + Second;
    if (ds != '_') {
        let TimeZoneOffsetSign = '+';
        let TimeZoneOffset = -d.getTimezoneOffset() / 60;
        if (TimeZoneOffset < 0) {
            TimeZoneOffsetSign = '-';
            TimeZoneOffset = -TimeZoneOffset;
        }
        if (TimeZoneOffset < 10) {
            TimeZoneOffset = '0' + TimeZoneOffset;
        }
        FormatedDateTime += tzs + TimeZoneOffsetSign + TimeZoneOffset;
    }
    return FormatedDateTime;
}
export function base64EncodingUTF8(str) {
    // @ts-ignore
    let encoded = new TextEncoderLite('utf-8').encode(str);
    let b64Encoded = "77u/" + _arrayBufferToBase64(encoded);
    return b64Encoded;
}
export function _arrayBufferToBase64(buffer) {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
}
export function AUrlEncodedImageFromBase64(Base64) {
    if (Base64 == null || Base64.length == 0) {
        return "";
    }
    if (Base64.substr(0, 5) == "data:") {
        return Base64;
    }
    switch (Base64.substr(0, 2)) {
        case "/9": return "data:image/jpg;base64," + Base64;
        case "Qk": return "data:image/bmp;base64," + Base64;
        case "iV": return "data:image/png;base64," + Base64;
    }
    return "data:image/unknown;base64," + Base64;
}
export function MakePercentageColorGent(Value) {
    if (Value <= 0.)
        return "rgb(255,0,0)";
    if (Value >= 1.)
        return "rgb(0,200,0)";
    Value *= 2;
    if (Value <= 1.) {
        var R = 1.;
        var G = Value;
        return "rgb(0," + Math.floor(R * 200.) + "," + Math.floor(G * 200.) + ")";
    }
    else {
        var R = 2. - Value;
        var G = 1.;
        return "rgb(0," + Math.floor(R * 200.) + "," + Math.floor(G * 200.) + ")";
    }
}
export function MakePercentageColor(Value) {
    Value = 1. - Value;
    if (Value <= 0.)
        return "rgb(0,255,0)";
    if (Value >= 1.)
        return "rgb(255,0,0)";
    Value *= 2;
    if (Value <= 1.) {
        var G = 1.;
        var R = Value;
        var Sum = Math.sqrt(G + R);
        G /= Sum;
        R /= Sum;
        return "rgb(" + Math.floor(R * 255.) + "," + Math.floor(G * 255.) + ",0)";
    }
    else {
        var G = 2. - Value;
        var R = 1.;
        var Sum = Math.sqrt(G + R);
        G /= Sum;
        R /= Sum;
        return "rgb(" + Math.floor(R * 255.) + "," + Math.floor(G * 255.) + ",0)";
    }
}
export function AAfterString(In, After) {
    var Index = In.indexOf(After);
    if (Index < 0)
        return null;
    return In.slice(Index + After.length);
}
export function RequestFullScreen(ele) {
    if (ele.requestFullscreen) {
        ele.requestFullscreen();
    }
    else if (ele.webkitRequestFullscreen) {
        ele.webkitRequestFullscreen();
    }
    else if (ele.mozRequestFullScreen) {
        ele.mozRequestFullScreen();
    }
    else if (ele.msRequestFullscreen) {
        ele.msRequestFullscreen();
    }
}
export function ExitFullScreen() {
    let doc = document;
    if (doc.exitFullscreen) {
        doc.exitFullscreen();
    }
    else if (doc.msExitFullscreen) {
        doc.msExitFullscreen();
    }
    else if (doc.mozCancelFullScreen) {
        doc.mozCancelFullScreen();
    }
    else if (doc.webkitExitFullscreen) {
        doc.webkitExitFullscreen();
    }
    else {
        console.error("No ExitFullScreen function found!");
    }
}
// export function toggleFullScreen() {
// 	let doc = document as any
// 	var element = document.getElementById("Main")
// 	var fullScreen = doc.fullscreen == true || doc.fullscreenElement != null || doc.mozFullScreenElement != null || doc.msFullscreenElement != null || doc.webkitIsFullScreen == true;
// 	var Button = $("#MaximizeButton i");
// 	if (!fullScreen) {
// 		Button.removeClass("fa-window-maximize");
// 		Button.addClass("fa-window-restore");
// 		RequestFullScreen(element);
// 	} else {
// 		Button.removeClass("fa-window-restore");
// 		Button.addClass("fa-window-maximize");
// 		ExitFullScreen();
// 	}
// }
export function AStripUrl(cssurl) {
    if (!cssurl.includes('url(')) {
        console.warn(`Couldn't strip url: ${cssurl}`);
        return cssurl;
    }
    if (cssurl.startsWith('url("')) {
        return cssurl.substring(5, cssurl.length - 2);
    }
    else {
        return cssurl.substring(4, cssurl.length - 1);
    }
}
export function checkIfFullScreen() {
    return (window.innerWidth == screen.width && window.innerHeight == screen.height);
}
export function toggleFullScreen(ele) {
    let element = _getEle(ele);
    const isfull = checkIfFullScreen();
    if (isfull) {
        exitFullScreen();
    }
    else {
        requestFullScreen(element);
    }
    return isfull;
}
function requestFullScreen(ele) {
    if (ele.requestFullscreen) {
        ele.requestFullscreen();
    }
    else if (ele.webkitRequestFullscreen) {
        ele.webkitRequestFullscreen();
    }
    else if (ele.mozRequestFullScreen) {
        ele.mozRequestFullScreen();
    }
    else if (ele.msRequestFullscreen) {
        ele.msRequestFullscreen();
    }
}
function exitFullScreen() {
    if (document.exitFullscreen) {
        document.exitFullscreen();
        // @ts-ignore
    }
    else if (document.msExitFullscreen) {
        // @ts-ignore
        document.msExitFullscreen();
        // @ts-ignore
    }
    else if (document.mozCancelFullScreen) {
        // @ts-ignore
        document.mozCancelFullScreen();
        // @ts-ignore
    }
    else if (document.webkitExitFullscreen) {
        // @ts-ignore
        document.webkitExitFullscreen();
    }
    else {
        alert("No ExitFullScreen function found!");
    }
}
export function AccordionClick(ElementId, FrameId, MustExpand) {
    var Frame = $("#" + FrameId);
    if (!Frame) {
        return;
    }
    let Element = $("#" + ElementId);
    if (ElementId == null) {
        Element = Frame.prev();
    }
    var IsExpanded = Frame.hasClass("Show");
    if (IsExpanded == MustExpand) {
        return;
    }
    MustExpand = !IsExpanded;
    if (MustExpand) {
        Frame.addClass("Show");
        Element.removeClass("Collapsed");
        Element.addClass("Expanded");
    }
    else {
        Frame.removeClass("Show");
        Element.removeClass("Expanded");
        Element.addClass("Collapsed");
    }
}
export function createArray(length, defaultValue = undefined) {
    let output = [];
    if (typeof defaultValue === 'function') {
        for (let i = 0; i < length; i++) {
            output.push(defaultValue());
        }
    }
    else {
        for (let i = 0; i < length; i++) {
            output.push(defaultValue);
        }
    }
    return output;
}
function processPartition(arrayPartition, callback, timeout = 1) {
    return new Promise((resolve, reject) => {
        try {
            const result = arrayPartition.map(((value, index) => callback(value, index)));
            setTimeout(() => resolve(result), timeout);
        }
        catch (err) {
            reject(err);
        }
    });
}
export function asyncMapArray(array, partitionCount, callback) {
    if (array.length < partitionCount) {
        partitionCount = array.length;
    }
    return asyncMap(array, callback, partitionCount);
}
function asyncMap(realArray, callback, partitionCount) {
    const SIZE = realArray.length;
    const partitionSize = Math.ceil(SIZE / partitionCount);
    const arrayPartitions = [];
    let array = Object.assign([], realArray);
    do {
        const partition = array.splice(0, partitionSize);
        arrayPartitions.push(partition);
    } while (array.length > 0);
    return Loading.waitForPromises(Promise.resolve().then(async (_) => {
        let results = [];
        for (let i = 0; i < arrayPartitions.length; i++) {
            const nextPartition = arrayPartitions[i];
            results.push(await processPartition(nextPartition, callback));
            await waitForChromeFrame();
        }
        const arrays = await Promise.all(results);
        return [].concat.apply([], arrays);
    }));
}
export function waitForChromeFrame() {
    const promise = new Promise(requestAnimationFrame);
    return promise.then(function frameCallback(timestamp) {
        return timestamp;
    });
}
/**
 * Converts object to array by removing the keys & returning the values in the same order of the keys
 * @param {any} object
 * @returns {any[]}
 */
export function convertObjectToArray(object) {
    const keys = Object.keys(object);
    const output = new Array(keys.length);
    for (let i = 0; i < keys.length; i++) {
        output[i] = object[keys[i]];
    }
    return output;
}
export function transformButtonCls(color) {
    switch (color) {
        case null:
        case undefined:
        case "default":
        case "blue":
            return 'btn-primary';
        case "grey":
            return 'btn-grey';
        case "green":
        case "success":
            return 'btn-success';
        case "orange":
            return 'btn-orange';
        case "yellow":
            return 'btn-yellow';
        case "red":
        case "error":
            return 'btn-red';
        case 'purple':
            return 'btn-purple';
        default:
            console.warn(`AVerificationButtonCls "${color}" is not recognized!`);
            return '';
    }
}
export function transformSpanCls(color) {
    const map = {
        'default': 'label-primary',
        'blue': 'label-blue',
        'grey': 'label-grey',
        'green': 'label-green',
        'yellow': 'label-yellow',
        'orange': 'label-orange',
        'red': 'label-red',
        'success': 'label-green',
        'error': 'label-red',
        'purple': 'label-purple',
    };
    if (color !== undefined && map.hasOwnProperty(color)) {
        return map[color];
    }
    return map['default'];
}
export function transformTextCls(color) {
    const map = {
        'default': 'text-default',
        'blue': 'text-default',
        'grey': 'text-grey',
        'green': 'text-green',
        'yellow': 'text-yellow',
        'orange': 'text-orange',
        'red': 'text-red',
        'success': 'text-green',
        'error': 'text-red',
        'purple': 'text-purple',
    };
    if (color !== undefined && map.hasOwnProperty(color)) {
        return map[color];
    }
    return map['default'];
}
export function AConvertMillisecondsToHM(ms) {
    const seconds = ms / 1000;
    const ss = Math.floor(seconds);
    const hh = Math.floor(seconds / 3600);
    const mm = Math.floor(seconds / 60);
    return (`
    ${(hh).toString().padStart(2, '0')}:${(mm % 60).toString().padStart(2, '0')}:${(ss % 60).toString().padStart(2, '0')}`).trim();
}
export function ARound(val, decimals = 0) {
    const pow = Math.pow(10, decimals); // ?
    return Math.round(val * pow) / pow;
}
export function AIsDate(text) {
    if (typeof text !== 'string')
        return false;
    return (text.charAt(4) == '-' && text.charAt(7) == '-' && text.charAt(10) == 'T');
}
export function AFormatNumber(n, invalidValue = '0') {
    if (n === null || n === undefined || Number.isNaN(n))
        return invalidValue;
    return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '<div class="number-seperator"></div>');
}
export function AFormatDate(d, s = " ", ds = "-", ts = ":", tzs = " ") {
    if (!d)
        return "";
    if (d.getTime() === 0)
        return "";
    let Year = d.getFullYear();
    let Month = d.getMonth() + 1;
    let Day = d.getDate();
    let Hour = d.getHours();
    let Minute = d.getMinutes();
    let Second = d.getSeconds();
    if (Month < 10)
        Month = "0" + Month;
    if (Day < 10)
        Day = "0" + Day;
    if (Hour < 10)
        Hour = "0" + Hour;
    if (Minute < 10)
        Minute = "0" + Minute;
    if (Second < 10)
        Second = "0" + Second;
    return Year + ds + Month + ds + Day + s + Hour + ts + Minute + ts + Second;
}
export function isObject(item) {
    return (item && typeof item === 'object' && !Array.isArray(item));
}
export function mergeDeep(target, ...sources) {
    if (!sources.length)
        return target;
    const source = sources.shift();
    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key])) {
                if (!target[key])
                    Object.assign(target, { [key]: {} });
                mergeDeep(target[key], source[key]);
            }
            else {
                Object.assign(target, { [key]: source[key] });
            }
        }
    }
    return mergeDeep(target, ...sources);
}
export function _getEle(str) {
    let ele = null;
    if (str instanceof jQuery) {
        // @ts-ignore
        ele = str.get(0);
    }
    else if (str instanceof Element) {
        ele = str;
    }
    else if (typeof str === 'string') {
        let found = document.querySelector(str) || (!str.startsWith('#') ? document.querySelector('#' + str) : null);
        ele = found;
    }
    else {
        throw new Error(`_getEle(#${str}) is an unexpected value`);
    }
    return ele;
}
export function _getEle$(str) {
    let ele = _getEle(str);
    return $(ele);
}
