import { ALoopTimer } from "../classes/ALoopTimer.js";
export class ADetectionEmitterTimer extends ALoopTimer {
    reloadTimeout() { super.changeTimeout(this.getTimeout()); }
    constructor({ detectCallback, getTimeout }) {
        super(() => this.sendDetectionToServer(), { timeout: getTimeout(), loopLifeCycle: 'PAGE' });
        this.getTimeout = getTimeout;
        this.detectCallback = detectCallback;
    }
    sendDetectionToServer() {
        if (this.detectCallback) {
            this.detectCallback();
        }
    }
}
