import { ATableFormatter } from "../core/form/ATableFormatter.js";
export function default_order() {
    return ATableFormatter.fallbackOrder;
}
export function default_tableformatter() {
    return new ATableFormatter(ATableFormatter.fallbackDefinitions);
}
export function team_sorting() {
    return [
        "User",
        "UserDisplayName",
        "Status",
        "StatusString",
        "ComState",
        "ValidLogin",
        "CheckPRDB",
        "NodeId",
        "DeviceName",
        "NodeName",
        "NodeType",
        "Unit",
        "UserSessionId",
        "LastUpdated",
        "Gps",
        "UserGroups",
        "id_mrs",
    ];
}
export function team_tableformatter() {
    return new ATableFormatter({
        "ValidLogin": { type: "BOOLEAN" },
        "User": { type: "TEXT" },
        "UserDisplayName": { type: "TEXT" },
        "Status": { type: "TEXT" },
        "StatusString": { type: "TEXT" },
        "ComState": { type: "TEXT" },
        "NodeId": { type: "TEXT" },
        "DeviceName": { type: "TEXT" },
        "NodeName": { type: "TEXT" },
        "NodeType": { type: "TEXT" },
        "Unit": { type: "TEXT" },
        "CheckPRDB": { type: "BOOLEAN" },
        "LastUpdated": { type: "TEXT" },
        "UserSessionId": { type: "HIDDEN" },
        "Gps": { type: "HIDDEN" },
        "UserGroups": { type: "HIDDEN" },
        "id_mrs": { type: "HIDDEN" },
    });
}
export function gpsstat_tableformatter() {
    return new ATableFormatter({
        'gpsTime': { type: "DATETIME" },
        'deviceName': { type: "TEXT" },
        'speed': { type: "NUMBER" },
        'precision': { type: "NUMBER" },
    });
}
export function locationimagerequest_tableformatter() {
    return new ATableFormatter({
        'id': { type: "HIDDEN" },
        'parentIndex': { type: "HIDDEN" },
        'LocationId': { type: "TEXT" },
        'DetectionDeviceId': { type: "NUMBER" },
        'ImageTime': { type: "DATETIME" },
        'CreationDeviceId': { type: "TEXT" },
        'LocationType': { type: "TEXT" },
        'Status': { type: "TEXT" },
        'Images': { type: "NUMBER" },
        'Latitude': { type: "TEXT" },
        'Longitude': { type: "TEXT" },
        'BeginTimeFrame': { type: "TEXT" },
        'EndTimeFrame': { type: "TEXT" },
        'Name': { type: "HIDDEN" },
        'Days': { type: "HIDDEN" },
        '_Days': { type: "ARRAY" },
        'ExpiryDate': { type: "DATETIME" },
        'CreationTime': { type: "DATETIME" },
        'CreationUser': { type: "TEXT" },
        'Description': { type: "TEXT" },
        'Valid': { type: "BOOLEAN" },
        'ActionFocus': { type: "HIDDEN" },
        'ActionCopy': { type: "HIDDEN" },
        'ActionEdit': { type: "HIDDEN" },
        'ActionDelete': { type: "HIDDEN" },
    });
}
export function locationimage_tableformatter() {
    return new ATableFormatter({
        'LocationId': { type: 'TEXT' },
        'CreationDeviceId': { type: 'TEXT' },
        'LocationType': { type: 'TEXT' },
        'Name': { type: 'TEXT' },
        'Description': { type: 'TEXT' },
        'BeginTimeFrame': { type: 'TEXT' },
        'EndTimeFrame': { type: 'TEXT' },
        'Days': { type: 'ARRAY' },
        'ExpiryDate': { type: 'DATETIME' },
        'CreationTime': { type: 'DATETIME' },
        'CreationUser': { type: 'TEXT' },
        'GpsPoint': { type: 'HIDDEN' },
        'parentIndex': { type: 'HIDDEN' },
        'id': { type: 'HIDDEN' },
        'ImageTime': { type: 'DATETIME' },
        'Images': { type: 'NUMBER' },
    });
}
export function polygons_tableformatter() {
    return new ATableFormatter({
        'Index': { type: 'NUMBER' },
        'GeoId': { type: 'NUMBER' },
        'Name': { type: 'TEXT' },
        'GeoType': { type: 'TEXT' },
        'Geo': {
            type: 'OBJECT',
            formatter: {
                'type': { type: 'TEXT' },
                'srid': { type: 'NUMBER' },
                'coordinates': {
                    type: 'ARRAY',
                    formatter: {
                        '*': {
                            type: 'ARRAY',
                            formatter: { '*': { type: 'TEXT' } }
                        },
                    }
                },
            }
        },
        'Attributes': {
            type: 'OBJECT',
            formatter: {
                '*': { type: 'UNKNOWN' },
                'Capacity': { type: 'NUMBER' },
                'CalculatedCapacity': { type: 'NUMBER' },
                'Geo-Area': { type: 'NUMBER' },
                'Geo-Length': { type: 'NUMBER' },
            }
        },
        'Center': {
            type: 'OBJECT',
            formatter: {
                'lat': { type: 'REAL' },
                'lng': { type: 'REAL' },
            }
        },
        'RefList': { type: 'HIDDEN' },
        'Active': { type: 'BOOLEAN' },
        'Modified': { type: 'DATETIME' },
        'GeoCreated': { type: 'DATETIME' },
    });
}
export function trafficsigns_tableformatter() {
    return new ATableFormatter({
        'data': { type: "HIDDEN" },
        'DetectionValid': { type: "BOOLEAN" },
        'TrafficSign': { type: "TEXT" },
        'TrafficSignText': { type: "TEXT" },
        'TrafficSignCenter': { type: "OBJECT" },
        'ScanDeviceCenter': { type: "OBJECT" },
        'ScanDeviceDirection': { type: "TEXT" },
        'Confidence': { type: "NUMBER" },
        'CameraId': { type: "TEXT" },
        'DetectionTime': { type: "DATETIME" },
        'GpsPrecision': { type: "NUMBER" },
        'ScanDeviceSpeed': { type: "NUMBER" },
        'SessionId': { type: "HIDDEN" },
        'SessionMode': { type: "TEXT" },
        'SessionName': { type: "TEXT" },
        'Side': { type: "TEXT" },
        'DetectionDevice': { type: "TEXT" },
        'DetectionId': { type: "TEXT" },
        'DetectionDeviceId': { type: "TEXT" },
    });
}
export function trafficsigns_sorting() {
    return [
        'DetectionValid',
        'TrafficSign',
        'TrafficSignText',
        'TrafficSignCenter',
        'ScanDeviceCenter',
        'ScanDeviceDirection',
        'Confidence',
        'CameraId',
        'DetectionTime',
        'GpsPrecision',
        'ScanDeviceSpeed',
        'SessionId',
        'SessionMode',
        'SessionName',
        'Side',
        'DetectionDevice',
        'DetectionId',
        'DetectionDeviceId',
    ];
}
