import { AError } from "../classes/AError.js";
import { AEngine, sleep } from "../core/AEngine.js";
export class ATranslateService {
    // private logArr: string[] = []
    // private history: {[cacheKey: string]: {from: string, to?: string}[]} = {}
    constructor() {
        this.fetching = [];
        this.cache = {};
        Object.defineProperty(window, 'Translate', {
            get: () => this
        });
    }
    async insert(key, value) {
        const cacheKey = this.toCacheKey(key);
        // if (this.isFetching(cacheKey)) {
        //     await this.waitForFetch(cacheKey)
        // }
        if (!this.hasKey(cacheKey)) {
            this.setCache(cacheKey, value);
        }
        // return this.getCache(cacheKey)
    }
    /**
     * Converts the value string to a cacheKey to fit in a cache object (hashtable)
     * @param {string} key which needs to be converted
     */
    toCacheKey(key) {
        let output = key.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/\s+/g, ' ').trim().replace(/([ ])(.)/g, (_, g1, g2) => g1 + g2.toUpperCase());
        return output[0].toUpperCase() + output.substring(1);
        // return key.toLowerCase()
    }
    /**
     * Whether the cacheKey has a corrosponding cached value
     * @param {string} cacheKey key for the translation
     */
    hasKey(cacheKey) {
        return this.cache[cacheKey] !== undefined;
    }
    /**
     * Inserts a key-value pair into the cache for translations
     * @param {string|Object} cacheKey key for the translation
     * @param {any} cacheValue translated key
     * @param {boolean} tryInvoke whether it should invoke waitForFetch promises
     */
    setCache(cacheKey, cacheValue, tryInvoke = false) {
        if (cacheKey instanceof Object) {
            const translation = cacheKey;
            for (const key in translation) {
                const cacheKey = this.toCacheKey(key);
                this.setCache(cacheKey, translation[key], tryInvoke);
            }
        }
        else {
            this.cache[cacheKey] = cacheValue;
            this.fetching.splice(this.fetching.indexOf(cacheKey), 1);
            if (tryInvoke === true) {
                const eventKey = this.toEventKey(cacheKey);
                Events.tryInvoke(eventKey, cacheValue);
            }
        }
    }
    /**
     * Returns the translated cache value corrosponding to the cacheKey
     * @param {string} cacheKey key for the translation
     */
    getCacheFast(cacheKey) {
        return this.cache[cacheKey];
    }
    /**
     * Returns the translated cache value corrosponding to the cacheKey
     * @param {string} key key for the cacheKey
     */
    getCache(key) {
        const cacheKey = this.toCacheKey(key);
        if (!this.cache[cacheKey]) {
            AError.handle('Error with cache!');
            return '!ERROR!';
        }
        return this.cache[cacheKey];
    }
    /**
     * Whether the text is being fetched already
     * @param {string} cacheKey key for the translation
     */
    isFetching(cacheKey) {
        return (this.fetching.includes(cacheKey));
    }
    /**
     * Generates promise that will resolve once the fetch of the wanted key is finished
     * @param {string} cacheKey key for the translation
     */
    waitForFetch(cacheKey) {
        return new Promise(resolve => Events.once(this.toEventKey(cacheKey), resolve));
    }
    /**
     * Converts cacheKey to an Event Key
     * @param {string} cacheKey key for the translation
     */
    toEventKey(cacheKey) {
        return `Translation->waitForFetch->${cacheKey}`;
    }
    /**
     * Fetches translation for the input text
     * and uses the cache if operation has been executed before
     * @param textOrArray
     */
    async get(textOrArray, skipCache = false) {
        let output = {};
        if (textOrArray === null && textOrArray === undefined) {
            return output;
        }
        if (textOrArray instanceof Array) {
            const keysToFetch = (await Promise.all(textOrArray.filter((elem, index, self) => // Remove double entries
             index == self.indexOf(elem)).map(async (key) => {
                const cacheKey = this.toCacheKey(key);
                const isFetching = this.isFetching(cacheKey);
                if (isFetching) {
                    await this.waitForFetch(cacheKey);
                }
                if (this.hasKey(cacheKey)) {
                    output[key] = this.getCache(cacheKey);
                    return null;
                }
                this.fetching.push(cacheKey);
                return key;
            }))).filter(key => key !== null);
            if (keysToFetch.length > 0) {
                AEngine.log('Translating:', keysToFetch);
                // if (skipCache === false) {
                //   keysToFetch.map(tmpKey => {
                //     let ck = this.toCacheKey(tmpKey)
                //     if (!this.history.hasOwnProperty(ck)) {
                //       this.history[ck] = []
                //     }
                //     this.history[ck].push({ from: tmpKey })
                //   })
                // }
                const translation = await requestService.translate(keysToFetch);
                this.setCache(translation, null, true);
                for (const key in translation) {
                    output[key] = translation[key];
                }
            }
        }
        else {
            const cacheKey = this.toCacheKey(textOrArray);
            const isFetching = this.isFetching(cacheKey);
            if (isFetching) {
                await this.waitForFetch(cacheKey);
            }
            if (this.hasKey(cacheKey)) {
                return this.getCache(cacheKey);
            }
            // if (skipCache === false) {
            //   let key = textOrArray
            //   if (!this.history.hasOwnProperty(cacheKey)) {
            //     this.history[cacheKey] = []
            //   }
            //   this.history[cacheKey].push({ from: key })
            // }
            this.fetching.push(cacheKey);
            AEngine.log('Translating:', textOrArray);
            const translation = await requestService.translate(textOrArray);
            output = translation;
            this.setCache(cacheKey, translation, !isFetching);
        }
        return output;
    }
    /**
     * Fetches and caches' the given text before using it
     * Used in order to save loadtime
     * @param textOrArray
     */
    prepare(textOrArray) {
        return Loading.waitForPromises(this.get(textOrArray, true));
    }
    updateTranslation(language, key, newValue, checked) {
        CCCClient.SendMessage("UpdateTranslation", 2, {
            Key: key,
            Value: newValue,
            Checked: checked,
            Language: language
        });
        return sleep(300);
    }
}
