import { AGeoUtils } from "../core/maps/AGeoUtils.js";
// const deg2rad = deg => (deg * Math.PI) / 180.0;
// const rad2deg = rad => rad * 180 / Math.PI;
const rad = (x) => x * Math.PI / 180.0;
export function normalize(vector) {
    const mag = magnitude(vector);
    vector.lat /= mag;
    vector.lng /= mag;
    return vector;
}
export function normalizeFast(vector, magnitude) {
    vector.lat /= magnitude;
    vector.lng /= magnitude;
    return vector;
}
export function delta(a, b) {
    return { lat: (b.lat - a.lat), lng: (b.lng - a.lng) };
}
export function magnitude(delta) {
    return Math.sqrt(delta.lat * delta.lat + delta.lng * delta.lng);
}
/**
 * Calculates Distance between two LatLngs without considering earths curvature
 */
export function distance(a, b) {
    return magnitude(delta(a, b));
}
/**
 * Calculates Distance In Meters (Simple)
 */
export function distanceSimple(a, b) {
    const d = delta(a, b);
    const metersLat = d.lat * 111.32; // km
    const metersLng = 40075 * Math.cos(d.lng) / 360;
    return Math.sqrt(metersLat * metersLat + metersLng * metersLng) * 0.01;
}
/**
 * Calculates Distance In Meters
 */
export function distancePrecise(a, b) {
    var R = 6378137; // Earth’s mean radius in meter
    var dLat = rad(b.lat - a.lat);
    var dLong = rad(b.lng - a.lng);
    var tmp = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(rad(a.lat)) * Math.cos(rad(b.lat)) *
            Math.sin(dLong / 2) * Math.sin(dLong / 2);
    var c = 2 * Math.atan2(Math.sqrt(tmp), Math.sqrt(1 - tmp));
    var d = R * c;
    return d;
}
export function distanceGoogleMaps(a, b) {
    return google.maps.geometry.spherical.computeDistanceBetween(a, b);
}
export function lerp(from, to, t) {
    const sphericalGeometry = google.maps.geometry.spherical;
    const heading = sphericalGeometry.computeHeading(from, to);
    const distanceToTarget = sphericalGeometry.computeDistanceBetween(from, to);
    const pos = sphericalGeometry.computeOffset(from, distanceToTarget * t, heading);
    const mag = sphericalGeometry.computeDistanceBetween(from, pos);
    return { pos, mag };
}
export function moveTo(from, to, meters) {
    const sphericalGeometry = google.maps.geometry.spherical;
    const heading = sphericalGeometry.computeHeading(from, to);
    const distanceToTarget = sphericalGeometry.computeDistanceBetween(from, to);
    const pos = sphericalGeometry.computeOffset(from, meters, heading);
    const mag = sphericalGeometry.computeDistanceBetween(from, pos);
    return { pos, mag, distanceToTarget, remainingDistance: distanceToTarget - mag, heading };
}
function getMapIcon(nodeType, defaultValue = null) {
    switch (nodeType) {
        case "ExternalDevice": return '/img/huisstijl/pda_los_72ppi_rgb.png';
        case "Pda": return '/img/huisstijl/pda_los_72ppi_rgb.png';
        case "ScanAuto": return '/img/huisstijl/scanacar_los_72ppi_rgb.png';
        case "ScanScooter": return '/img/huisstijl/scooter_los_72ppi_rgb.png';
        case "ScanSegway": return '/img/huisstijl/segway_los_72ppi_rgb.png';
        case "ScanBike": return '/img/huisstijl/fiets_los_72ppi_rgb.png';
        case "BackOffice": return '/img/huisstijl/centrale_los_72ppi_rgb.png';
        case "CentralVerification": return '/img/huisstijl/centrale_los_72ppi_rgb.png';
        case "ScanCam": return '/img/huisstijl/paal_72ppi_rgb.png';
        default: return defaultValue;
    }
}
function getMapIconObj({ type }) {
    const foundIcon = getMapIcon(type, null);
    return foundIcon ? ({
        url: foundIcon,
        scaledSize: new google.maps.Size(48, 48)
    }) : undefined;
}
export function genMarker({ type, position, map }) {
    if (map === undefined && PageScript.map instanceof google.maps.Map) {
        map = PageScript.map;
    }
    let icon = getMapIconObj({ type });
    let marker = new google.maps.Marker({ position, icon });
    if (map !== undefined) {
        marker.setMap(map);
    }
    return marker;
}
export function findMarkersInView(collection) {
    const bounds = PageScript.map.getBounds();
    return collection.filter(marker => bounds?.contains(marker.getPosition() || new google.maps.LatLng(0, 0)));
}
export function findPolygonsInView(collection) {
    const bounds = PageScript.map.getBounds();
    return collection.filter(marker => bounds?.contains(AGeoUtils.calcCenter(marker) || new google.maps.LatLng(0, 0)));
}
export function findPolylinesInView(collection) {
    const bounds = PageScript.map.getBounds();
    return collection.filter(marker => bounds?.contains(AGeoUtils.calcCenter(marker) || new google.maps.LatLng(0, 0)));
}
