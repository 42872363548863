import { AError } from "../classes/AError.js";
import { sleep } from "../core/AEngine.js";
import { AIdAllocator } from "../core/allocator/AIdAllocator.js";
import { _getEle, _getEle$ } from "../utils/tools.js";
import { EVENTS } from "./AEventService.js";
import { ATimeoutError } from "./ARequestService.js";
export class ALoadingService {
    constructor() {
        this.promiseIdAllocator = new AIdAllocator();
        this.amountOfYields = 0;
        this.promises = [];
        this.promiseMap = {};
        this.logging = true;
        Object.defineProperty(window, 'Loading', {
            get: () => this
        });
    }
    autoInit() {
        Events.h_once(EVENTS.API_READY, _ => {
            this.translateText().catch(AError.handle);
        });
    }
    translateText() {
        return Translate.get('loading')
            .then((loading) => $('#loading > span').text(loading))
            .then(() => this.loaded = true);
    }
    async waitForEvent(keyOrKeys, showLoading = true) {
        try {
            if (showLoading === true && this.amountOfYields++ == 0) {
                this.showElement();
            }
            let results;
            if (Array.isArray(keyOrKeys)) {
                const resultsArr = await Promise.all(keyOrKeys.map((key) => this.waitForSingleEvent(key)));
                results = {};
                for (let i in resultsArr) {
                    if (resultsArr.hasOwnProperty(i))
                        results[results[i].Name] = resultsArr[i];
                }
            }
            else {
                results = await this.waitForSingleEvent(keyOrKeys);
            }
            if (showLoading === true && --this.amountOfYields == 0) {
                this.hideElement();
            }
            return results;
        }
        catch (err) {
            this.reset();
            throw err;
        }
    }
    /**
     * Add key to the loading queue
     * @param {*} key event key
     * @returns {Promise}
     */
    waitForSingleEvent(key) {
        return new Promise((resolve, reject) => {
            Events.h_once(key, (p1) => {
                if (p1?.constructor?.name === `Error`)
                    return reject(p1);
                return resolve(p1);
            });
        });
    }
    /**
     * // TODO: Implement promise timeouts
     */
    awaitTimeout(promise, msTimeout) {
        return new Promise((resolve, reject) => {
            let finished = false;
            sleep(msTimeout).then(() => {
                if (!finished) {
                    finished = true;
                    console.log('promise.timeout');
                    reject(new ATimeoutError(`Timed Out!`));
                }
            });
            promise.then((data) => {
                if (!finished) {
                    finished = true;
                    console.log('promise.finished');
                    resolve(data);
                }
            }).catch((err) => {
                if (!finished) {
                    console.log('promise.catch');
                    finished = true;
                    reject(err);
                }
            });
        });
    }
    // waitForPromises<T=any>(promises: PromiseLike<T>[]): PromiseLike<[T]>;
    /**
     * Add promise / promises to the loading queue
     */
    async waitForPromises(promise, opt) {
        if (opt?.hideLoading !== true && this.amountOfYields++ == 0) {
            this.showElement();
        }
        const oneResultSet = !Array.isArray(promise);
        const promises = (oneResultSet === true) ? [promise] : promise;
        let stackRef = new Error();
        if (this.logging) {
            this.promises.push(...(promises.map(p => ({ p, stack: stackRef.stack }))));
            this.promises.map(({ p, stack }, i) => {
                this.promiseMap[i] = { p, stack };
                try {
                    p.finally(() => {
                        delete this.promiseMap[i];
                    });
                }
                catch (err) {
                    console.error(err);
                }
            });
        }
        const $ele = this.genBlockAction(opt);
        let allPromise = Promise.all(promises);
        const id = this.promiseIdAllocator.getNextId();
        this.promiseMap[id] = { p: allPromise, stack: stackRef.stack };
        return await allPromise.then((results) => {
            this.amountOfYields = Math.max(this.amountOfYields - 1, 0);
            if (opt?.hideLoading !== true && this.amountOfYields == 0) {
                this.hideElement();
            }
            if ($ele) {
                $ele.fadeOut(300);
                sleep(300).then(() => $ele.remove());
            }
            return oneResultSet ? results[0] : results;
        }).catch(err => {
            this.reset();
            console.error(err);
            throw err;
        }).finally(() => {
            delete this.promiseMap[id];
        });
    }
    reset() {
        this.amountOfYields = 0;
        this.hideElement();
    }
    // Show dom element
    showElement() {
        $('#loading:not(.show)').addClass('show');
    }
    // Hide dom element
    hideElement() {
        $('#loading.show').removeClass('show');
    }
    genBlockAction(opt) {
        const useBlocking = opt && opt.blockParent && _getEle(opt.blockParent);
        if (useBlocking) {
            const $ele = $(/*html*/ `
      <div class="loading-page-init loading-page-persistent">
        <div class="loading-circular size-xxl text-primary" style="--fa-animation-duration: 1s; --fa-thickness: 0.3rem; height: 100%;"></div>
      </div>
      `);
            $ele.appendTo(_getEle$(opt.blockParent));
            return $ele;
        }
        return $();
    }
}
