import { sleep } from "../core/AEngine.js";
import { LoadPresets } from "./getDetectionFromCache.js";
import { AAfterString, ARound } from "./tools.js";
export const WAYSEGMENT_HIGHLIGHT = '#32CD32';
export const WAYSEGMENT_GREY = '#9d9d9d';
var canvasEle = document.createElement("Canvas");
var canvas = canvasEle;
export async function readFiles(opt) {
    await sleep(20);
    const filesObj = document.querySelector(opt.selector).files;
    if (filesObj === null) {
        return [];
    }
    const files = [...filesObj];
    const base64s = await Promise.all(files.map(async (file) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        const result = await new Promise((resolve, reject) => {
            reader.onload = () => resolve(reader.result);
            reader.onerror = () => reject(reader.error);
        });
        return result;
    }));
    return base64s;
}
export function CreateOverviewImage(text, size) {
    var context = canvas.getContext("2d");
    const { width, height } = Object.assign({ width: 968, height: 612 }, size);
    canvas.width = width;
    canvas.height = height;
    context.clearRect(0, 0, canvas.width, canvas.height);
    context.fillStyle = '#ffffff';
    context.fillRect(0, 0, canvas.width, canvas.height);
    context.fillStyle = '#000000';
    context.font = "40px opensans";
    context.rect(0, 0, canvas.width, canvas.height);
    context.stroke();
    context.font = "40px opensans";
    context.fillText(text, 10, 30 + 10);
    return AAfterString(canvas.toDataURL(), ";base64,");
}
export function CreateCarImage(text, size) {
    var context = canvas.getContext("2d");
    const { width, height } = Object.assign({ width: 640, height: 320 }, size);
    canvas.width = width;
    canvas.height = height;
    context.clearRect(0, 0, canvas.width, canvas.height);
    context.fillStyle = '#ffffff';
    context.fillRect(0, 0, canvas.width, canvas.height);
    context.fillStyle = '#000000';
    // var TextSize = context.measureText(Text)
    context.font = "normal 40px opensans";
    context.rect(0, 0, canvas.width, canvas.height);
    context.stroke();
    context.font = "normal 40px opensans";
    context.fillText(text, 10, 30 + 10);
    return AAfterString(canvas.toDataURL(), ";base64,");
}
export function CreateLPImage(text) {
    var context = canvas.getContext("2d");
    context.clearRect(0, 0, canvas.width, canvas.height);
    context.font = "normal 30px Kenteken";
    var TextSize = context.measureText(text);
    canvas.width = 20 + TextSize.width;
    canvas.height = 20 + 30;
    context.rect(0, 0, canvas.width, canvas.height);
    context.stroke();
    context.font = "normal 30px Kenteken";
    context.fillText(text, 10, 30 + 10);
    return AAfterString(canvas.toDataURL(), ";base64,");
}
Object.assign(globalThis, {
    canvasEle,
    CreateOverviewImage,
    CreateCarImage,
    CreateLPImage,
});
export function LoadPresetLPImage() { return 'BASE64'; }
function LoadPresetOverview(index) { }
export function HandleImageRequest(ImageRequest) {
    let ImageResponse = Object.assign({}, ImageRequest);
    if (!ImageResponse.DetectionDeviceId) {
        ImageResponse.DetectionDeviceId = ImageRequest.DeviceId;
    }
    const preset = LoadPresets(ImageRequest.LicensePlate);
    // use preset detection
    if (preset !== undefined) { // ivan
        const { OVERVIEW, CAR, LP } = preset.imageresponse;
        if (ImageResponse.Type.indexOf("LP") >= 0) {
            ImageResponse.LP = LP;
        }
        if (ImageResponse.Type.indexOf("CAR") >= 0) {
            ImageResponse.CAR = CAR;
        }
        if (ImageResponse.Type.indexOf("OVERVIEW") >= 0) {
            ImageResponse.OVERVIEW = OVERVIEW;
        }
    }
    else {
        if (ImageResponse.Type.indexOf("LP") >= 0) {
            ImageResponse.LP = { Success: true, ImageBuffer: CreateLPImage(ImageRequest.LicensePlate) };
        }
        if (ImageResponse.Type.indexOf("CAR") >= 0) {
            ImageResponse.CAR = { Success: true, ImageBuffer: CreateCarImage("Car Image:" + ImageRequest.LicensePlate) };
        }
        if (ImageResponse.Type.indexOf("OVERVIEW") >= 0) {
            ImageResponse.OVERVIEW = {
                Success: true,
                ImageBuffer: [
                    CreateOverviewImage("OVERVIEW IMAGE 1:" + ImageRequest.LicensePlate),
                    CreateOverviewImage("OVERVIEW IMAGE 2:" + ImageRequest.LicensePlate),
                    CreateOverviewImage("OVERVIEW IMAGE 3:" + ImageRequest.LicensePlate),
                    CreateOverviewImage("OVERVIEW IMAGE 4:" + ImageRequest.LicensePlate)
                ]
            };
        }
    }
    CCCClient.SendMessage("ImageResponse", 1, ImageResponse);
}
globalThis.prefix = 'TEST';
let detectionIndex = 1;
export function sendFakeDetection({ ScanDeviceGps, DetectionGps, GpsVehicleBounds }, formOpt) {
    const generateDetectionId = (dateTime) => {
        let Index = 0;
        let DetectionId = (dateTime.getTime() - 946684800000);
        let DetectionAddition = Index.toString(16).padStart(4, '0');
        return "0x" + DetectionId.toString(16) + DetectionAddition;
    };
    const DetectionTime = new Date();
    const DetectionPrefix = formOpt?.detectionPrefix || globalThis.prefix;
    let dIndex = -1;
    if (formOpt && formOpt.detectionIndex !== undefined) {
        dIndex = formOpt.detectionIndex++;
    }
    else {
        dIndex = detectionIndex++;
    }
    const LicensePlate = DetectionPrefix + (dIndex).toString().padStart(3, "0");
    // const LicensePlate = "TEST" + Math.floor(Math.random() * 100).toString().padStart(3, "0")
    const detectionData = {
        DetectionId: generateDetectionId(DetectionTime),
        DetectionTime: DetectionTime.toJSON(),
        LicensePlate: LicensePlate,
        CountryCode: "N",
        Confidence: ARound(0.85 * (Math.random() * 0.15), 3),
        ScanDeviceGps: {
            Latitude: ScanDeviceGps.lat,
            Longitude: ScanDeviceGps.lng
        },
        VehicleGps: {
            Latitude: DetectionGps.lat,
            Longitude: DetectionGps.lng
        },
        GpsVehicleBounds,
        GpsPrecision: 1,
        Side: Math.random() > 0.5 ? 'R' : 'L',
        // ScanDeviceDirection: "90.1",
        LPImage: CreateLPImage(LicensePlate),
        IsGeneratedLicenseplate: true,
    };
    CCCClient.SendMessage("DetectionStream", 1, detectionData);
}
export function resetDetectionIndex() {
    detectionIndex = 1;
}
globalThis.resetDetectionIndex = resetDetectionIndex;
