import { AEngine } from "../core/AEngine.js";
export class AHotReload {
    listen() {
        const devConfig = JSON.parse($('#dev-config').text() || '{}');
        if (Object.keys(devConfig).length > 0 && devConfig['hotreload-enabled'] == true) {
            const wsPort = devConfig['hotreload-port'];
            const websocket = new WebSocket(`ws://localhost:${wsPort}/`);
            websocket.onopen = () => {
                AEngine.log('ws->open');
            };
            websocket.onmessage = (evt) => {
                try {
                    const data = JSON.parse(evt.data);
                    if (data.reload) {
                        window.location.reload();
                    }
                    AEngine.log('ws->message, ' + JSON.stringify(evt));
                }
                catch (err) {
                    console.error(err);
                }
            };
            websocket.onclose = () => {
                AEngine.log('ws->close');
            };
            websocket.onerror = (err) => {
                console.error(err);
            };
            this.ws = websocket;
        }
    }
}
