import { AEngine } from './core/AEngine.js';
// BackOffice Initializer
import * as ScanAuto from './scanauto-initializer-legacy.js';
// Runnables
// import { ALoadBackground } from './modules/runnables/ALoadBackground.js'
// Services
import { ADependencyService } from './services/ADependencyService.js';
import { AEventService } from './services/AEventService.js';
import { ARequestService } from './services/ARequestService.js';
import { ALoadingService } from './services/ALoadingService.js';
import { ATranslateService } from './services/ATranslateService.js';
import { AMenuService } from './services/AMenuService.js';
import { AJsonService } from './services/AJsonService.js';
import { ARouteService } from './services/ARouteService.js';
import { AStateService } from './services/AStateService.js';
import { AAlertService } from './services/AAlertService.js';
import { ALoadHotReload } from './runnables/ALoadHotReload.js';
import { ALoadBackground } from './runnables/ALoadBackground.js';
import { ALoadTimebar } from './runnables/ALoadTimebar.js';
import { AGeoService } from './core/maps/AGeoService.js';
import { AInterpolateService } from './core/colors/AInterpolateService.js';
import { ACoreMapService } from './core/maps/ACoreMapService.js';
import { AGeoPaintService } from './core/maps/AGeoPaintService.js';
import { APermissionService } from './services/APermissionService.js';
import { APurgatoryService } from './core_impl/APurgatoryService.js';
async function run() {
    const services = [
        ACoreMapService,
        AGeoPaintService,
        AGeoService,
        AAlertService,
        ALoadingService,
        ATranslateService,
        ARequestService,
        AEventService,
        ADependencyService,
        AMenuService,
        AStateService,
        ARouteService,
        AJsonService,
        AInterpolateService,
        APermissionService,
        APurgatoryService,
    ];
    const runnables = [
        ALoadBackground,
        ALoadHotReload,
        ALoadTimebar
    ];
    await AEngine.executeServices(services);
    await AEngine.autoInitServices();
    await AEngine.executeRunnables(runnables);
    ScanAuto.createEventHandlers();
}
window.addEventListener('load', async () => {
    try {
        await run();
        await ScanAuto.run();
    }
    catch (err) {
        console.error(err);
    }
});
