import { AEngine } from "./AEngine.js";
import { ARequestService } from "../services/ARequestService.js";
import { createArray } from "../utils/tools.js";
export function MAP_RESPONSE(response) {
    return new AResponse(response);
}
export class AResponse {
    constructor(originalResponse, opt) {
        this.originalResponse = originalResponse;
        this.opt = opt;
        if (this.opt?.addColumns !== undefined) {
            this.addColumns(this.opt.addColumns);
        }
        this.init();
    }
    init() {
        this.indexes = AEngine.get(ARequestService).indexes(this.originalResponse, this.Columns);
        this.formattedRows = createArray(this.Rows.length);
        Object.keys(this.opt?.valueMapper || {}).map(key => {
            const func = this.opt.valueMapper[key];
            if (!this.indexes.hasOwnProperty(key)) {
                throw new Error(`IResponse Column ${key} Couldn't be found in ${this.Columns.join(', ')}`);
            }
            const columnIndex = this.indexes[key];
            this.originalResponse.Rows.map((row, rowIndex) => {
                this.originalResponse.Rows[rowIndex][columnIndex] = func(row[columnIndex]);
            });
        });
        for (let i = 0; i < this.Rows.length; i++) {
            this.formattedRows[i] = new ARow(this, this.Rows[i]).toData();
        }
    }
    addColumns(columns, index) {
        columns.map((c, columnIndex) => {
            const insertIndex = (index !== undefined) ? (index + columnIndex) : this.originalResponse.Columns.length;
            this.originalResponse.Columns.splice(insertIndex, 0, c);
            this.originalResponse.ColumnsTranslated.splice(insertIndex, 0, c);
            this.originalResponse.Rows = this.originalResponse.Rows.map(row => {
                row.splice(insertIndex, 0, undefined);
                return row;
            });
        });
        if (this.formattedRows) {
            this.formattedRows = this.formattedRows.map((row) => {
                columns.map(c => {
                    if (!row.hasOwnProperty(c)) {
                        row[c] = undefined;
                    }
                });
                return row;
            });
        }
    }
    get isEmpty() {
        return this.Size === 0;
    }
    get First() {
        return (this.Size > 0) ? this.formattedRows[0] : undefined;
    }
    get Last() {
        return (this.Size > 0) ? this.formattedRows[this.formattedRows.length - 1] : undefined;
    }
    get Original() {
        return this.originalResponse;
    }
    get Columns() {
        return this.originalResponse.Columns;
    }
    get ColumnsTranslated() {
        return this.originalResponse.ColumnsTranslated;
    }
    get Rows() {
        return this.originalResponse.Rows;
    }
    get Size() {
        return this.formattedRows.length;
    }
    loop(cb) {
        this.formattedRows.forEach((formattedRow, index) => {
            cb(formattedRow, index);
        });
    }
    find(predicate) {
        return this.formattedRows.find((formattedRow, index) => {
            return predicate(formattedRow, index);
        });
    }
    filter(predicate) {
        return this.formattedRows.filter((formattedRow, index) => {
            return predicate(formattedRow, index);
        });
    }
    map(callbackfn) {
        return this.formattedRows.map((formattedRow, index) => callbackfn(formattedRow, index));
    }
    toArray() {
        return this.formattedRows;
    }
}
export class ARow {
    constructor(parent, row) {
        const { indexes } = parent;
        this.data = {};
        const output = {};
        Object.keys(indexes).map(key => {
            const i = indexes[key];
            output[key] = row[i];
        });
        Object.assign(this.data, output);
        // Object.assign(this, output)
    }
    toData() {
        return this.data;
    }
}
