var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { executeOrder } from "../types/attributes.js";
import { stringify } from "../utils/tools.js";
function assert(expected, actual) {
    return expected === actual;
}
function assertError(callback) {
    let errorOccurred = false;
    let output = undefined;
    try {
        output = callback();
        errorOccurred = false;
    }
    catch (err) {
        errorOccurred = true;
        console.warn(`[UNIT_TEST] assertError Error Surpressed...`);
    }
    if (!errorOccurred) {
        throw new Error(`[UNIT_TEST] assertError Expected error but received ${output} ${stringify(output, 2, null, 2)}`);
    }
}
export let AUnitTestService = class AUnitTestService {
    constructor() { }
    async translate({ type }) {
        throw new Error(`Not Implemeneted Yet!`);
    }
    async showDialog() {
        throw new Error(`Not Implemeneted Yet!`);
    }
    async startTest(test) {
        throw new Error(`Not Implemeneted Yet!`);
    }
};
AUnitTestService = __decorate([
    executeOrder(Number.POSITIVE_INFINITY)
], AUnitTestService);
