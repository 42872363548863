import { ALoopTimer } from "../classes/ALoopTimer.js";
export var ACamStateEnum;
(function (ACamStateEnum) {
    ACamStateEnum[ACamStateEnum["Left"] = 0] = "Left";
    ACamStateEnum[ACamStateEnum["Both"] = 1] = "Both";
    ACamStateEnum[ACamStateEnum["Right"] = 2] = "Right";
})(ACamStateEnum || (ACamStateEnum = {}));
export class ACamStateEmitterTimer extends ALoopTimer {
    reloadTimeout() { super.changeTimeout(this.getTimeout()); }
    constructor({ device, getTimeout }) {
        super(() => this.sendCamStateToServer(true), { timeout: getTimeout(), loopLifeCycle: 'PAGE' });
        this.getTimeout = getTimeout;
        this.device = device;
        this.camStateEnum = ACamStateEnum.Both;
    }
    getCamState() {
        const opts = [
            [this.camStateDetectingOn, this.camStateDetectingOff],
            [this.camStateDetectingOn, this.camStateDetectingOn],
            [this.camStateDetectingOff, this.camStateDetectingOn],
        ];
        return opts[this.camStateEnum];
    }
    get camStateDetectingOn() {
        return { Connected: true, Parked: false, Detecting: true };
    }
    get camStateDetectingOff() {
        return { Connected: true, Parked: false, Detecting: false };
    }
    get camStateIdle() {
        return { Connected: true, Parked: false, Detecting: false };
    }
    genCamStateStream(camState, detectingCount) {
        const [camStateLeft, camStateRight] = (camState instanceof Array) ? camState : [camState, camState];
        const [detectingCountLeft, detectingCountRight] = (detectingCount instanceof Array) ? detectingCount : [detectingCount, detectingCount];
        return {
            Left: {
                Cams: {
                    Forward: camStateLeft,
                    Sideways: camStateLeft,
                    Backward: camStateLeft
                },
                DetectingCount: this.camStateEnum !== ACamStateEnum.Right ? detectingCountLeft : 0,
                Mode: -1,
            },
            Right: {
                Cams: {
                    Forward: camStateRight,
                    Sideways: camStateRight,
                    Backward: camStateRight
                },
                DetectingCount: this.camStateEnum !== ACamStateEnum.Left ? detectingCountRight : 0,
                Mode: -1,
            },
        };
    }
    sendCamStateToServer(detecting = true) {
        if (detecting) {
            const cameraStates = this.getCamState();
            requestService.stream('CamStateStream', this.genCamStateStream(cameraStates, 1), {
                priority: 10
            });
        }
        else {
            requestService.stream('CamStateStream', this.genCamStateStream(this.camStateIdle, 0), {
                priority: 10
            });
        }
    }
}
