import { AError } from "../classes/AError.js";
import { AfterLogin } from "../scanauto-initializer-legacy.js";
import { initToolTips } from "../utils/tooltip.js";
export class ALoginPageBase {
    constructor(opt) {
        this.opt = opt;
        globalThis.baseTitle = document.title;
    }
    async init() { }
    setupUsername({ UseUsername }) {
        if (UseUsername == true) {
            $('#UsernameField').attr('style', '');
            $('#Username').on('keypress', (e) => {
                if (e.which == 13) {
                    $('#Password').trigger('focus');
                }
            });
        }
        return this;
    }
    setupPassword({ UseAuthenticator, HidePassword, ConcealPassword }) {
        $('#PasswordField').attr('style', '');
        const hidePassword = (HidePassword == true || ConcealPassword == true);
        const inputType = (hidePassword) ? 'password' : 'text';
        $('#Password').attr('type', inputType);
        $("#Password").on('keypress', (e) => {
            if (e.which == 13) {
                if (UseAuthenticator == true) {
                    $("#AuthenticatorCode").trigger('focus');
                }
                else {
                    $("#Login").trigger("click");
                }
            }
        });
        return this;
    }
    setupAuthenticator({ UseAuthenticator }) {
        $("#AuthenticatorCode").on('keypress', function (e) {
            if (e.which == 13) {
                $("#Login").trigger("click");
            }
        });
        $('#AuthenticatorCodeHolder').css({
            display: UseAuthenticator === true ? 'inline-block' : 'none'
        });
        return this;
    }
    setupLogin() {
        $('#Login').on('click', (e) => {
            e.preventDefault();
            e.stopPropagation();
            $('#login-form').addClass('loader-active');
            $('#login-form :input').prop('disabled', true);
            Loading.waitForPromises(this.attemptLogin()).catch(AError.handle);
            $('#login-form :input').prop('disabled', false);
            $('#login-form').removeClass('loader-active');
        });
        return this;
    }
    async attemptLogin() {
        throw new Error(`Not Implemented Yet!`);
    }
    async loginSucceeded() {
        try {
            await Loading.waitForPromises(AfterLogin());
            routeService.startListening();
            this.allowHeaderHover();
        }
        catch (err) {
            AError.handle(err);
        }
    }
    allowHeaderHover() {
        $('.top-logo').attr('atooltip', [
            `ACI Server ${Config?.ServerBuildInfo}`,
            `Client ${Config?.ClientBuildInfo?.text}`,
            `Customer #${Settings?.NodeCustomerNumber}`,
        ].join('<br />'));
        initToolTips($('[atooltip]'));
    }
    show() {
        $('#label1').text(this.opt.NodeType);
        $('#label2').text(this.opt.Company);
        $('#login-form').on('submit', (e) => {
            e.preventDefault();
            e.stopPropagation();
            return false;
        });
        $('#login-form').addClass('animated');
        $('#login-form :input').eq(0).trigger('focus');
        return this;
    }
}
