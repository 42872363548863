import { AEngine } from "../AEngine.js";
import { AIdAllocatorService } from "../allocator/AIdAllocatorService.js";
import { MAP_POSITION } from "./AMapStructs.js";
export var MAP_POSITION_VECTOR;
(function (MAP_POSITION_VECTOR) {
    MAP_POSITION_VECTOR[MAP_POSITION_VECTOR["TOP"] = 1] = "TOP";
    MAP_POSITION_VECTOR[MAP_POSITION_VECTOR["RIGHT"] = 2] = "RIGHT";
    MAP_POSITION_VECTOR[MAP_POSITION_VECTOR["BOTTOM"] = 3] = "BOTTOM";
    MAP_POSITION_VECTOR[MAP_POSITION_VECTOR["LEFT"] = 4] = "LEFT";
})(MAP_POSITION_VECTOR || (MAP_POSITION_VECTOR = {}));
export class AMapOverlayService {
    constructor() {
        this.offsets = {};
        this.positionCollisionMatrix = {
            [MAP_POSITION.TOP_LEFT]: ($o) => ({
                x: $o.offset().left + $o.width(),
                y: $o.offset().top + $o.height(),
            }),
            [MAP_POSITION.TOP_RIGHT]: ($o) => ({
                x: $o.offset().left,
                y: $o.offset().top + $o.height(),
            }),
            [MAP_POSITION.BOTTOM_LEFT]: ($o) => ({
                x: $o.offset().left + $o.width(),
                y: $o.offset().top,
            }),
            [MAP_POSITION.BOTTOM_RIGHT]: ($o) => ({
                x: $o.offset().left,
                y: $o.offset().top,
            }),
        };
        this.positionToCls = {
            [MAP_POSITION.TOP_LEFT]: 'top-left',
            [MAP_POSITION.TOP_RIGHT]: 'top-right',
            [MAP_POSITION.BOTTOM_LEFT]: 'bottom-left',
            [MAP_POSITION.BOTTOM_RIGHT]: 'bottom-right',
        };
        this.map = null;
        this.overlays = {};
        this.uids = {};
    }
    autoInit() {
        // this.mapHelperService = AEngine.get(ACoreMapService)
    }
    isCollisionBetween(mapElement, position1, position2, opt) {
        const [pos1, pos2] = [position1, position2].sort();
        const $overlay1 = this.getOrCreateOverlay(mapElement, pos1);
        const $overlay2 = this.getOrCreateOverlay(mapElement, pos2);
        const point1 = this.positionCollisionMatrix[pos1]($overlay1);
        const point2 = this.positionCollisionMatrix[pos2]($overlay2);
        const delta = {
            x: point2.x - point1.x,
            y: point2.y - point1.y,
        };
        return {
            isCollision: opt?.checkX === true ? delta.x < 0 : delta.x < 0 || delta.y < 0,
            delta,
            point1,
            point2
        };
    }
    addGradientOverlay(mapElement) {
        // const { mapElement, uid, title, titleTag, icon, order, position, tag } = Object.assign({ uid: ACrypto.randomHexString(10), title: '', tag: 'div' }, options)
        const uid = AEngine.get(AIdAllocatorService).getNextId({ prefix: 'gradient-' });
        const $gradientOverlay = $(/*html*/ `
      <div id="${uid}" class="map-controls-gradient"></div>
    `);
        const $map = $(mapElement);
        if ($map.find('.map-controls-gradient').length === 0) {
            $map.prepend($gradientOverlay);
        }
    }
    getOrCreateOverlay(mapElement, position) {
        const className = this.positionToCls[position];
        const $map = $(mapElement);
        let $overlay = $map.find(`.map-overlay-controls.${className}`);
        if ($overlay.length === 0) {
            $overlay = $(`<div class="map-overlay-controls ${className}"></div>`);
            $map.css({ 'position': 'relative' });
            $map.prepend($overlay);
        }
        const offset = this.offsets[position];
        if (offset) {
            $overlay.css('margin', offset);
        }
        return $overlay;
    }
    clear() {
        this.map = null;
        this.overlays = {};
        this.uids = {};
    }
    setOffset(mapElement, position, offset) {
        this.offsets[position] = offset;
        this.getOrCreateOverlay(mapElement, position);
    }
    /**
     * @param mapElement map html element
     * @param $ele the element to show on top of the map
     * @param position position of the element on top of the map
     * @param options if options.uid is defined, it will delete the previous element with the same uid, if order is defined it'll sort the ui elements
     */
    add(mapElement, $ele, position, { uid, order }) {
        this.insert($ele, { $parent: this.getOrCreateOverlay(mapElement, position), order, uid });
    }
    /**
     * @param $container
     * @param options if uid is defined, it will delete the previous element with the same uid
     */
    insert($container, { uid, $parent, order }) {
        // Delete previous element if uid is set
        this.delete({ uid });
        this.uids[uid] = $container;
        $container.attr('order', order);
        const $mapOverlays = $parent.find('[order]').toArray().map((e) => $(e));
        for (let $ele of $mapOverlays) {
            if (order < Number($ele.attr('order'))) {
                $container.insertAfter($ele);
                this.sortElements($parent);
                return;
            }
        }
        this.sortElements($parent);
        $parent.append($container);
    }
    delete({ uid }) {
        if (this.uids.hasOwnProperty(uid)) {
            this.uids[uid].remove();
        }
    }
    sortElements($parent) {
        $parent.find('[order]')
            .sort((a, b) => Number($(a).attr('order')) - Number($(b).attr('order')))
            .appendTo($parent);
    }
}
