import { AError } from "../classes/AError.js";
import { AGeoUtils } from "../core/maps/AGeoUtils.js";
import { sleep } from "../core/AEngine.js";
import { createMap } from "../core/maps/ACoreMapService.js";
import { MAP_OPTIONS } from "../core/maps/AMapStructs.js";
import { addTabListenersFind } from "../utils/tabs.js";
export class APage {
    async testParkingSpaceTypes() {
        var _map = {};
        Object.values(this.parkingSpaces).map(v => {
            // const key = v.data.Name
            const key = v.data.Attributes['Functie'];
            if (!_map.hasOwnProperty(key)) {
                _map[key] = [];
            }
            _map[key].push(v);
        });
        for (const type in _map) {
            const ps = _map[type][0];
            const pos = AGeoUtils.calcCenter(ps);
            this.map.setCenter(pos);
            console.log(type, ps.data);
            console.log('click', new google.maps.event.trigger(ps, 'click'));
            await sleep(500);
        }
    }
    constructor() {
        const defaultCoords = geoService.defaultCoordinates;
        this.startPos = defaultCoords.center.toJSON();
        this.startBounds = defaultCoords.bounds.toJSON();
        this.startPosition = new google.maps.LatLng(this.startPos);
        this.map = createMap('#map', {
            center: this.startPosition,
            zoom: 16
        });
        // this.segments = []
        this.waySegments = [];
        this.waySegmentsMap = {};
        this.parkingSpaces = [];
        this.splitParkingSpaces = [];
        this.splitParkingSpacesDataMap = {};
        this.waySegmentToSideSplitParkingSpaceMap = {};
    }
    async init() {
        addTabListenersFind($('#AjaxContent'));
        coreMapService.prepareMapItems(MAP_OPTIONS.All).catch(AError.handle);
        this.waySegments = await Loading.waitForPromises(geoService.load(this.map, MAP_OPTIONS.WaySegment, { parseToMap: true, addClickListener: true }));
        this.handleMapEvents();
        $('.loading-page-init').fadeOut();
    }
    handleMapEvents() {
        // this.splitParkingSpaces.map(parkingSpace => {
        //   google.maps.event.addListener(parkingSpace, "click", (ref) => {
        //     console.log({ parkingSpace, ref })
        //   })
        // })
        // this.waySegments.map(line => {
        //   google.maps.event.addListener(line, "click", (ref) => {
        //     const addedToRoute = this.scanDevice.toggleWaysegment(line)
        //     line.setOptions({ strokeColor: (addedToRoute) ? WAYSEGMENT_HIGHLIGHT : WAYSEGMENT_GREY })
        //   })
        // })
    }
}
export function css() {
    return ( /*html*/`
    <style>
      @font-face {
        font-family: Kenteken;
        src: url('/font/Kenteken.ttf') format('truetype');
        /* src: url('/font/Kenteken.eot'); /* IE9 Compat Modes */
        src: url('/font/Kenteken.eot?#iefix') format('embedded-opentype'),
          /* IE6-IE8 */
          url('/font/Kenteken.woff') format('woff'),
          /* Pretty Modern Browsers */
          url('/font/Kenteken.ttf') format('truetype'),
          /* Safari, Android, iOS */
          url('/font/Kenteken.svg#svgFontName') format('svg');
        /* Legacy iOS */
        font-weight: normal;
      }
      .aci-tabs {
        background: #f1f0f0;
      }
      [tabview] {
        height: 100%;
        padding-top: 15px;
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 15px;
        overflow-y: auto;
      }
      .aci-tabs {
        /*height: 56px; */
      }
      .views {
        height: calc(100% - 56px)
      }
    </style>
  `);
}
export function render() {
    return ( /*html*/`
    <div class="wrapper lg columns col-gapless">
      <div class="column col-12" style="height: 100%; background: #f8f9fa">
        <div id="map" class="aci-map"></div>
      </div>
    </div>
  `);
}
