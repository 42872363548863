import { AEngine, sleep } from '../core/AEngine.js';
import { APreferenceService, SAVE_IDS } from './APreferenceService.js';
import { AJsonService } from './AJsonService.js';
import { EVENTS } from './AEventService.js';
import { AError } from '../classes/AError.js';
import { ACrypto } from '../classes/ACrypto.js';
import { AUnitTestService } from './AUnitTestService.js';
import { AMapOverlayService } from '../core/maps/AMapOverlayService.js';
import { APopoverService } from '../core/ui/APopoverService.js';
export class AMenuService {
    get availableUrls() {
        const availableUrls = [];
        for (let item of this.menu) {
            const menuIdMr = item.id_mr ? [item.id_mr] : [];
            if (item.hidden === true || !permissionService.hasPermission(menuIdMr)) {
                continue;
            }
            const childLinks = (item.children || [])
                .filter(c => permissionService.hasPermission(menuIdMr.concat([c.id_mr]).filter(v => v !== undefined)))
                .filter(c => c.hidden !== true)
                .map(c => c.url);
            const urlsToPush = (childLinks.length > 0) ? childLinks : [item.url];
            availableUrls.push(...urlsToPush);
        }
        return availableUrls;
    }
    constructor() {
    }
    async autoInit() {
        this.mapOverlayService = AEngine.get(AMapOverlayService);
        this.preferenceService = AEngine.get(APreferenceService);
        this.startMenuOpened = this.preferenceService.load(SAVE_IDS.MENU_OPEN, null);
        Events.h_once(EVENTS.PREFETCH, _ => this.prefetch());
        Events.hardwire(EVENTS.ROUTE_CHANGED, () => {
            // const url = this.getMenuOptionsForUrl(routeService.meta.menuItem!.url)?.url
            this.updateMenuHighlights();
            // TODO: Implement highlighting for header navigation (account > profile,adminalerts,etc)
            // $(`.sidebar-popover .popover-open`)
        });
        Events.hardwire(EVENTS.PAGE_INITIALIZED, () => {
            this.updateMenuHighlights();
            $('.sidebar-menu').toggleClass('no-select', false);
        });
        Events.hardwire(EVENTS.ROUTE_CHANGED, () => {
            $('.sidebar-menu').toggleClass('no-select', true);
        });
        this.initHeaderStrip();
    }
    updateMenuHighlights() {
        const { url } = routeService.meta.menuItem;
        $('.menu-links li.active').removeClass('active');
        $(`.menu-links a[href="#!${url}"]`).closest('li').addClass('active');
        $(`.menu-links [ref="menu"] a[href="${url}"]`).closest('li').prev().addClass('active');
        $(`.popover-container .popover-open a[href="#!${url}"]`).closest('li').addClass('active');
    }
    isCurrentPage(href) {
        const { url } = routeService.meta.menuItem;
        return url === href;
    }
    getMenuOptionsForUrl(hash) {
        if (this.menuFlat.hasOwnProperty(hash)) {
            return this.menuFlat[hash];
        }
        if (hash !== '/') {
            AError.handle({
                useModal: false,
                err: [new Error(`Couldn't find the menu item for hash: "${hash}" `)],
            });
        }
        return null;
    }
    initHeaderStrip() {
        $('.header-strip [href]').on('click', e => e.preventDefault());
        $('.header-strip [action="TOGGLE_LANGUAGE"]').on('click', _ => this.toggleLanguageMenu());
    }
    get $content() {
        return $('#AjaxContent');
    }
    get isMenuOpen() {
        const $menu = $('.sidebar-menu');
        return $menu.hasClass('menu-open');
    }
    async fetchMenu(opt) {
        const menu = jsonService.getFromCache({ url: AJsonService.JSON_FILES.MENU });
        // for (const menuItem of menu) {
        if (AEngine.isDevelopmentMode && opt.translate) {
            await Promise.all(menu.map(async (menuItem) => {
                menuItem.title = await Translate.get(menuItem.title);
                if (menuItem.premium === true && menuItem.children) {
                    await Promise.all(menuItem.children.map(async (child) => {
                        child.title = await Translate.get(child.title);
                        child.premium = true;
                    }));
                }
            }));
        }
        else {
            menu.map(async (menuItem) => {
                if (menuItem.premium === true && menuItem.children) {
                    menuItem.children.map((child) => {
                        child.premium = true;
                    });
                }
            });
        }
        return menu;
    }
    toggleLanguageMenu() {
        const $frame = $('#LanguageFrame');
        if ($frame.hasClass('visible')) {
            $frame.removeClass('visible');
        }
        else {
            $('#SearchLanguage').trigger('focus');
            $frame.addClass('visible');
        }
    }
    async manualInit(opt) {
        this.menu = await this.fetchMenu(opt);
        window['MenuAjax'] = this.menu;
        this.generateMenuFlat(this.menu);
    }
    async prefetch() {
        if (AEngine.isDevelopmentMode) {
            await this.manualInit({ translate: true });
        }
        const menuHtml = this.generateMenu(this.menu);
        $('.sidebar-menu ul').html(menuHtml);
        $('.header-strip [action="TOGGLE_MENU"]').on('click', e => {
            e.preventDefault();
            this.toggleMenu();
        });
        $('#popover-account a[href="#"][action]').on('click', (e) => {
            e.preventDefault();
        });
        $('#popover-account [action="SIGNOUT"]').on('click', e => {
            location.reload();
        });
        $('#popover-account [action="UNIT_TESTING"]').on('click', e => {
            AEngine.get(AUnitTestService).showDialog();
        });
        $('#popover-account [action="NOTIMPL"]').on('click', e => {
            e.preventDefault();
            Alerts.notImplementedYet();
        });
        // $('#popover-account [action="EXPLORE_ERRORS"]').on('click', e => {
        //   // TODO: Implementation when central server supports error list
        // })
        this.createMouseHandlers().catch(console.error);
        this.restoreSideMenuState().catch(AError.handle);
        AEngine.get(APopoverService).initMenuPopovers();
        this.updateCssContainerSizing();
        // this.createTooltipHandler()
        return this;
    }
    setVisible($p, visible) {
        $p.toggleClass('popover-open', visible);
    }
    async restoreSideMenuState() {
        switch (this.startMenuOpened) {
            case null:
                break;
            case true:
                if (!this.isMenuOpen) {
                    this.toggleMenu();
                }
                break;
            case false:
                if (this.isMenuOpen) {
                    this.toggleMenu();
                }
                break;
        }
    }
    async createMouseHandlers() {
        $(document).on('mouseenter', '#sidebar-popover[ref]', (e) => {
            const $popover = $(e.target).is('.sidebar-popover') ? $(e.target) : $(e.target).closest('.sidebar-popover');
            this.setVisible($popover, true);
        });
        $(document).on('mouseleave', '#sidebar-popover[ref]', (e) => {
            const $popover = $(e.target).is('.sidebar-popover') ? $(e.target) : $(e.target).closest('.sidebar-popover');
            this.setVisible($popover, false);
        });
        $(document).on('mouseenter', '#menu-account,#popover-account', (e) => this.setVisible($('#popover-account'), true));
        $(document).on('mouseleave', '#menu-account,#popover-account', (e) => this.setVisible($('#popover-account'), false));
    }
    toggleMenu() {
        const $menu = $('.sidebar-menu');
        const menuClosed = $menu.hasClass('menu-open');
        if (menuClosed) {
            $menu.removeClass('menu-slide-in');
            $menu.removeClass('menu-open');
            this.$content.css('margin-left', 'var(--sidebar-width)');
            this.$content.css('width', 'calc(100% - var(--sidebar-width) - var(--sidebar-margin-right))');
        }
        else {
            $menu.addClass('menu-slide-in');
            $menu.addClass('menu-open');
            this.$content.css('margin-left', 'var(--sidebar-open-width)');
            this.$content.css('width', 'calc(100% - var(--sidebar-open-width) - var(--sidebar-margin-right))');
        }
        sleep(600).then(_ => Events.tryInvoke(EVENTS.CONTENT_RESIZE, { caller: 'AMenuService' }));
        this.preferenceService.save(SAVE_IDS.MENU_OPEN, this.isMenuOpen);
        return menuClosed;
    }
    updateCssContainerSizing() {
        const $menu = $('.sidebar-menu');
        const menuOpen = $menu.hasClass('menu-open');
        if (menuOpen) {
            this.$content.css('margin-left', 'var(--sidebar-open-width)');
            this.$content.css('width', 'calc(100% - var(--sidebar-open-width) - var(--sidebar-margin-right))');
        }
        else {
            this.$content.css('margin-left', 'var(--sidebar-width)');
            this.$content.css('width', 'calc(100% - var(--sidebar-width) - var(--sidebar-margin-right))');
        }
    }
    generateMenu(menu) {
        if (AEngine.isDevelopmentMode) {
            AEngine.warn(`// TODO: Check if permissions require checking`);
        }
        const menuItems = [];
        for (let item of menu) {
            if (item.hidden === true) {
                continue;
            }
            const menuIdMr = item.id_mr ? [item.id_mr] : [];
            if (!permissionService.hasPermission(menuIdMr)) {
                continue;
            }
            const className = item.children ? ` class="menu-dropdown"` : '';
            if (!item.url && item.children && item.children.length) {
                item.url = item.children[0].url;
            }
            const childLinks = (item.children || [])
                .filter(c => permissionService.hasPermission(menuIdMr.concat([c.id_mr]).filter(v => v !== undefined)))
                .filter(c => c.hidden !== true);
            // .filter(c => permissionService.hasPermission(menuIdMr.concat([c.id_mr])))
            const linkHtml = (childLinks.length) ?
                (`<a href="#!${childLinks[0].url}">${item.title}</a>`) :
                (`<a href="#!${item.url}">${item.title}</a>`);
            menuItems.push(`
        <li ${className}>
          <i class="${item.icon}"></i>
          ${linkHtml}
          <div class="expand"><i class="fa-solid fa-chevron-right fa-xs"></i></div>
        </li>
      `);
            if (childLinks.length > 0) {
                menuItems.push(`<li ref="menu" class="hidden">${childLinks.map(c => `<a href="${c.url}">${c.title}</a>`).join('')}</li>`);
            }
        }
        return menuItems.join('');
    }
    generateMenuFlat(menu) {
        let menuFlat = {};
        $.each(menu, function (_, mv) {
            if (mv.children != undefined) {
                $.each(mv.children, function (_, smv) {
                    menuFlat[smv.url] = Object.assign({}, {
                        id_mr: smv.id_mr,
                        title: mv.title + " - " + smv.title,
                        usergroups: smv.usergroups,
                        url: smv.url,
                        parent: mv,
                        obsolete: (smv.obsolete === true),
                        bypass_login: false
                    });
                });
            }
            else {
                menuFlat[mv.url] = Object.assign({}, {
                    id_mr: mv.id_mr,
                    title: mv.title,
                    usergroups: mv.usergroups,
                    url: mv.url,
                    hidden: mv.hidden,
                    bypass_login: mv.bypass_login || false
                });
            }
        });
        this.menuFlat = menuFlat;
    }
    async addMapButton(options) {
        const { mapElement, uid, title, titleTag, icon, order, position, tag } = Object.assign({ uid: ACrypto.randomHexString(10), title: '', tag: 'div' }, options);
        const titleText = title ? await Translate.get(title) : '';
        const $ele = $(`
      <${tag} id="${uid}" class="poly-toggle scale-toggle">
        <div class="noselect ns-children">
          <label class="form-label">
            ${icon !== undefined ? `<i class="${icon} fa-fw"></i>` : ''}
            ${titleTag ? `<${titleTag}>${titleText}</${titleTag}>` : titleText}
          </label>
        </div>
      </${tag}>
    `);
        this.mapOverlayService.add(mapElement, $ele, position, { uid, order });
        return $ele;
    }
    async addMapButtonRadio(options) {
        const { mapElement, titles, icons, order, position } = options;
        if (titles.length === 0)
            return [];
        const $btns = await Promise.all(titles.map((title, i) => this.addMapButton({
            mapElement,
            title,
            titleTag: 'span',
            icon: icons[i],
            position,
            order: (order + i * 0.01),
        })));
        $btns[0].addClass('text-bold');
        $btns.map(($btn, i) => {
            $btn.on('click', () => {
                $btns.map($b => $b.toggleClass('text-bold', $b.is($btn)));
            });
        });
        $btns.map(($btn, i) => {
            let cls = (i !== 0) ?
                ((i !== $btns.length - 1) ? '' : 'map-btn-right') : 'map-btn-left';
            $btn.addClass(`map-btn-inline ${cls}`);
        });
        return $btns;
    }
    async addMapDropdown(arrayOf$ele, options) {
        const { mapElement, uid, title, icon, order, position } = Object.assign({ uid: ACrypto.randomHexString(10), title: '' }, options);
        const $mapBtn = this.addMapButton({ mapElement, uid, title, icon, order, position });
        const popoverSelector = `.sidebar-popover[uid="${uid}"]`;
        const $popover = $(`<div id="popover-map" class="sidebar-popover" uid="${uid}"><ul></ul></div>`);
        for (let $ele of arrayOf$ele) {
            const $li = $(`<li></li>`);
            $li.append($ele);
            $popover.find('ul').append($li);
        }
        const $parentWithRelativePos = $(mapElement);
        $parentWithRelativePos.append($popover);
        $(document).on('mouseenter', `${popoverSelector},#${uid}`, (e) => {
            const $p = $(`${popoverSelector}`), $btn = $(`#${uid}`);
            let mp = ($parentWithRelativePos.offset() || { left: 0, top: 0 });
            let bo = ($btn.offset() || { left: 0, top: 0 });
            $p.css({
                left: bo.left - mp.left,
                top: bo.top - mp.top + ($btn.height() || 0)
            });
            this.setVisible($p, true);
        });
        $(document).on('mouseleave', `${popoverSelector},#${uid}`, (e) => {
            this.setVisible($(`${popoverSelector}`), false);
        });
        Events.on(EVENTS.DESTRUCT, () => {
            $(document).off('mouseenter', `${popoverSelector},#${uid}`);
            $(document).off('mouseleave', `${popoverSelector},#${uid}`);
            $popover.remove();
        });
        return $mapBtn;
    }
}
