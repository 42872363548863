export function createFormInput(options) {
    const { type, title, name, value } = options;
    //  'checkbox'|'number'|'text'
    const map = {
        'checkbox': () => ( /*html*/`
        <div class="form-group">
          <label class="form-switch">
            <input type="checkbox" name="${name}" ${value ? `checked="checked"` : ''}>
            <i class="form-icon"></i> ${title}
          </label>
        </div>
      `),
        'number': () => ( /*html*/`
        <div class="form-group">
          <label class="form-label" for="${name}">${title}</label>
          <input class="form-input" type="number" name="${name}" value="${value}">
        </div>
      `),
        'text': () => ( /*html*/`
        <div class="form-group">
          <label class="form-label" for="${name}">${title}</label>
          <input class="form-input" type="text" name="${name}" value="${value}">
        </div>
      `),
        '...': () => ( /*html*/`
      
      `)
    };
    if (!map.hasOwnProperty(type)) {
        throw new Error(`Unexpected AFormInputType: "${type}"`);
    }
    return map[type]();
}
export function genForm(properties, options) {
    const { valueMapper, cls } = options || {};
    const hasValueMapper = (valueMapper !== undefined);
    const elements = Object.keys(properties).map(key => {
        const { type, title, value, width } = properties[key];
        const html = createFormInput({
            type: type,
            title: title,
            name: key,
            value: (hasValueMapper) ? valueMapper(key) : value
        });
        return (`
      <div class="column ${width ?? 'col-12'}">
        ${html}
      </div>
    `);
    });
    return ( /*html*/`
    <form class="${cls || ''}">${elements.join('')}</form>
  `);
}
export function convertInputToKeyValue($input) {
    const key = $input.attr('name');
    const type = $input.attr('type');
    switch (type) {
        case 'text':
            return { key, value: $input.val() };
        case 'number':
            const num = Number($input.val());
            if (isNaN(num))
                throw new Error(`Expected number but got value: ${$input.val()}`);
            return { key, value: num };
        case 'checkbox':
            return { key, value: $input.prop('checked') };
        default:
            console.warn(`Didn't expect input type: ${type}`);
            return { key, value: $input.val() };
    }
}
export function injectFormValues($form, data, triggerChange = true) {
    Object.keys(data).map((key) => $form.find(`[name=${key}]`)).map(($input) => {
        const key = $input.attr('name');
        const type = $input.attr('type');
        const value = data[key];
        switch (type) {
            case 'text':
                $input.val(value);
                break;
            case 'number':
                $input.val(value);
                break;
            case 'checkbox':
                $input.prop('checked', value);
                break;
        }
        if (triggerChange) {
            $input.trigger('change');
        }
    });
}
export function injectFormData($form, data, triggerChange = true) {
    $form.find('[name]').toArray().map(ele => $(ele)).map(($input) => {
        const key = $input.attr('name');
        const type = $input.attr('type');
        const value = data[key];
        switch (type) {
            case 'text':
                $input.val(value);
                break;
            case 'number':
                $input.val(value);
                break;
            case 'checkbox':
                $input.prop('checked', value);
                break;
        }
        if (triggerChange) {
            $input.trigger('change');
        }
    });
}
export function extractFormData($form) {
    const keyValuePairs = {};
    $form.find('[name]').toArray().map(ele => $(ele)).map(($input) => {
        let { key, value } = convertInputToKeyValue($input);
        keyValuePairs[key] = value;
    });
    return keyValuePairs;
}
